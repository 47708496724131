import { Product } from "@/interfaces/product";
import { MetrcItem, ProductMetrcItem } from "@/interfaces/traceability";
import { productMetrcInfo } from "@/metadata/product";
import { traceabilityService } from "@/services/traceability.service";
import { DynamicFormComponent, FormField } from "helix-vue-components";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./ProductMetrcInfo.template.vue";

export interface ProductMetrcInfo {
  name: string;
  id: string;
  category: string;
  strain: string;
  unit_of_measure: string;
  unit_weight: string;
  unit_weight_unit_of_measure: string;
  unit_thc_content: string;
  unit_thc_content_unit_of_measure: string;
}

const defaultProductMetrcInfo = {
  name: "",
  id: "",
  category: "",
  strain: "",
  unit_of_measure: "",
  unit_weight: "",
  unit_weight_unit_of_measure: "",
  unit_thc_content: "",
  unit_thc_content_unit_of_measure: ""
};

@Component({
  mixins: [Template],
  components: {
    DynamicFormComponent
  }
})
export default class ProductMetrcInfoComponent extends Vue {
  @Prop({ required: true })
  public value!: ProductMetrcItem;
  public internalModel: ProductMetrcInfo | null = defaultProductMetrcInfo;
  @Prop({ required: true })
  public onDisplay!: boolean;

  public fieldsConfig: FormField[] = [];
  public loading: boolean = true;
  public defaultItems: MetrcItem[] = [];

  @Prop({ required: true })
  protected product!: Product;

  public async metrcValuesUpdated(data: {
    model: ProductMetrcInfo;
    modelField: string;
  }) {
    if (data.model.id) {
      this.internalModel = await traceabilityService.getProductMetrcItem(
        data.model.id
      );
    } else {
      this.internalModel = null;
    }
    if (data.modelField === "id") {
      this.$emit("input", {
        ...this.value,
        metrc_id: data.model.id,
        hasChanged: true
      });
    }
  }

  public async searchProducts(ref: any) {
    let searchValue = ref.search;
    if (!searchValue && this.internalModel && this.internalModel.name) {
      searchValue = this.internalModel.name;
    }
    if (!searchValue && this.defaultItems.length) {
      return this.defaultItems;
    }
    const list = await traceabilityService.findByMetrcName(searchValue);
    if (!searchValue) {
      this.defaultItems = list;
    }

    return list;
  }

  public async mounted() {
    const unwatch = this.$watch("onDisplay", async () => {
      if (this.onDisplay) {
        this.fieldsConfig = productMetrcInfo(this.searchProducts);
        this.loading = true;
        if (this.product.sku) {
          const metrcData = await traceabilityService.getProductMetrcMappedItemId(
            this.product.sku
          );
          if (metrcData) {
            this.internalModel = await traceabilityService.getProductMetrcItem(
              metrcData.metrc_id
            );
            this.$emit("input", {
              ...this.value,
              metrc_id: metrcData.metrc_id,
              isNewItem: false,
              hasChanged: false
            });
          }
        }
        this.loading = false;

        unwatch();
      }
    });
  }
}
