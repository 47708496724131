import PricePointCopyComponent from "@/components/inventory/pricePoint/PricePointCopy/PricePointCopy.component";
import PricePointFormComponent from "@/components/inventory/pricePoint/PricePointForm/PricePointForm.component";
import { MemberLevel } from "@/interfaces/memberLevel";
import {
  defaultPriceGroup,
  PriceGroupInterface,
  PriceGroups
} from "@/interfaces/priceGroups";
import {
  defaultPriceModel,
  Pricebreak,
  PriceModel,
  PricingRule,
  Product
} from "@/interfaces/product";
import { RetailSettings } from "@/interfaces/retailSettings";
import {
  TaxBreak,
  TaxCategory,
  TaxCategoryDefault
} from "@/interfaces/taxCategory";
import { PricePointTableMetadata } from "@/metadata/product";
import { pricePointService } from "@/services/pricePoint.service";
import { productService } from "@/services/product.service";
import {
  TableComponent,
  TableFieldType,
  TableHeader
} from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import remove from "lodash/remove";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./SharedMainPoint.template.vue";

@Component({
  mixins: [Template],
  components: {
    TableComponent,
    PricePointFormComponent,
    PricePointCopyComponent
  }
})
export default class SharedPricePointMainComponent extends Vue {
  get tableHeaders() {
    const preTaxes =
      this.currentTaxCategory && this.currentTaxCategory.taxes
        ? this.currentTaxCategory.taxes.find((tax: TaxBreak) => {
            return tax.type === "EXCISE_PRE_TAX";
          })
        : false;
    let headers = [...this.headers];
    if (!this.currentPriceStyle) {
      if (this.priceByUsable || this.requiresWeighing !== undefined) {
        const amountHeader = {
          value: this.requiresWeighing
            ? `quantity/a/g`
            : this.priceByUsable
            ? `quantity/a/${this.unitUsableWeight}`
            : "quantity/a/u",
          label: this.$t("amount").toString(),
          fieldComponent: TableFieldType.string,
          class: "tdt__headers__fieldLong",
          sortable: true
        };
        headers[0] = amountHeader;
      }
    }
    if (this.currentPriceStyle) {
      const amountHeader = {
        value:
          this.currentPriceStyle === "units" ? `quantity/a/u` : `quantity/a/g`,
        label: this.$t("amount").toString(),
        fieldComponent: TableFieldType.string,
        class: "tdt__headers__fieldLong",
        sortable: true
      };
      headers[0] = amountHeader;
    }
    if (preTaxes) {
      const extraHeader = {
        value: "pre_excise",
        label: this.$t("pre_excise").toString(),
        fieldComponent: TableFieldType.currency,
        class: "tdt__headers__fieldLong",
        sortable: true
      };
      const priceHeader = {
        value: "price",
        label: this.$t("price").toString(),
        fieldComponent: TableFieldType.currency,
        class: "tdt__headers__fieldLong",
        sortable: true
      };
      headers[1] = priceHeader;
      headers.splice(2, 0, extraHeader);
    } else {
      headers = remove(headers, header => {
        // @ts-ignore
        return header.label !== "pre_excise";
      });
      const priceHeader = {
        value: "price",
        label: this.$t("price").toString(),
        fieldComponent: TableFieldType.currency,
        class: "tdt__headers__fieldLong",
        sortable: true
      };
      headers[1] = priceHeader;
    }
    return headers;
  }
  get groupedTaxes() {
    const taxes = this.currentTaxCategory.taxes || [];
    return pricePointService.getGroupedTaxes(taxes);
  }
  @Prop({ default: false })
  public isDisable!: boolean;
  @Prop({ default: true })
  public isModifiableProducts!: boolean;
  @Prop() public currentPricing!: Pricebreak[];
  @Prop({ default: false }) public productSelected!: boolean;
  @Prop() public priceGroupSelectedId!: number | null;
  @Prop() public priceGroupAssigned!: PriceGroupInterface;
  @Prop({ default: () => [] }) public prices!: PricingRule[];
  public pricingStyleItems: Array<{ text: string; value: string }> = [];
  public model: PriceGroups = cloneDeep(defaultPriceGroup);
  public pricingRules: PricingRule[] = cloneDeep(this.prices);

  @Prop({ default: "RETAIL_RECREATIONAL" })
  protected priceType!:
    | "RETAIL_MEDICAL"
    | "RETAIL_RECREATIONAL"
    | "WHOLESALE_INBOUND"
    | "WHOLESALE_OUTBOUND"
    | "RETAIL_MEDICAL_RECREATIONAL";
  @Prop({ default: false })
  protected isWholesale!: boolean;
  @Prop({ default: null })
  protected unitUsableWeight!: string;
  @Prop({ required: true })
  protected taxCategories!: TaxCategory[];
  @Prop()
  protected priceByUsable!: boolean;
  @Prop()
  protected requiresWeighing!: boolean;
  @Prop({ required: true })
  protected memberLevels!: MemberLevel[];

  protected loadTaxChanged = false;
  @Prop({ required: true })
  protected isModifyAblePriceGroup!: boolean;
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  protected currentRetailSettings!: RetailSettings;

  protected headers: TableHeader[] = PricePointTableMetadata;
  protected rowActions = pricePointService.getRowAction(
    this.openPricePointModal,
    this.deletePriceBreak
  );
  protected currentTaxCategory: TaxCategory = cloneDeep(TaxCategoryDefault);
  protected currentMemberLevelCode: string | null = null;
  protected pricePoint: PriceModel = { ...defaultPriceModel };
  protected currentPricingSet: Pricebreak[] = [];
  @Prop({ default: "" }) protected pricingTypeSelected!: string;
  protected currentPriceStyle: string | null = "";
  protected activeRuleData: PricingRule | null = null;
  public openPricePointModal(data?: { pricePoint: PriceModel; index: number }) {
    if (data) {
      this.pricePoint = { ...data.pricePoint };
      this.pricePoint.isEditing = true;
      this.pricePoint.editingIndex = data.index;
    } else {
      this.pricePoint = { ...defaultPriceModel };
    }

    this.$modals
      .load<{ edit: boolean; data: PriceModel }>(
        PricePointFormComponent,
        {
          size: "fit",
          positionY: "top",
          title: this.$t("price_point").toString()
        },
        {
          productUnit: this.unitUsableWeight,
          pricePoint: this.pricePoint,
          prices: this.currentPricingSet,
          tax: this.currentTaxCategory,
          memberLevelCode: this.currentMemberLevelCode,
          priceByUsable: this.priceByUsable,
          requiresWeighing: this.requiresWeighing,
          wholesale: this.isWholesale,
          pricingStyleSelected: this.currentPriceStyle,
          pricingTypeSelected: this.pricingTypeSelected
        }
      )
      .then((dataToSave: { edit: boolean; data: PriceModel }) => {
        if (dataToSave) {
          if (dataToSave.edit) {
            this.editPricingRule(dataToSave.data);
          } else {
            this.addNewPricingRule(dataToSave.data);
          }
          // manda data arriba
          this.closeAndNotify();
        }
      })
      .catch(() => {
        // No action on close.
      });
  }

  public deletePriceBreak(data: { item: Pricebreak; index: number }) {
    const active = this.getActiveRule();
    if (data && active) {
      active.price_breaks!.splice(data.index, 1);
      this.closeAndNotify();
    }
  }

  public async copy(product: Product) {
    const productPricing = await productService.getProductPricing(product.sku); // product
    let prices = (productPricing && productPricing.rules) || [];
    if (!prices.length) {
      return;
    }
    prices = prices.map(price => {
      price.id = null;
      return price;
    });

    const taxCategoryId = prices.length && prices[0].tax_category_id!;
    if (taxCategoryId) {
      this.currentTaxCategory = this.taxCategories.find(
        tax => tax.id === taxCategoryId
      )!;
    }
    this.$emit("copyPrices", prices);
  }

  public addNewPricingRule(price: PriceModel) {
    if (this.pricingRules.length) {
      // Buscar rule para agregar item o crear una
      const matchingRuleIndex = findIndex(
        this.pricingRules,
        r => r.member_level_code === price.member_level_code
      );

      if (matchingRuleIndex > -1) {
        this.pricingRules[matchingRuleIndex].is_active = 1;
        this.pricingRules[matchingRuleIndex].price_breaks!.push({
          price: price.price,
          quantity: +price.quantity,
          pre_excise: price.pre_excise,
          post_tax: price.post_tax
        });
      } else {
        this.createPriceRule(price);
      }
    } else {
      // crear rule
      this.createPriceRule(price);
    }
  }

  public createPriceRule(price: PriceModel) {
    this.pricingRules.push({
      pricing_type: this.priceType,
      tax_category_id: price.tax_category_id!,
      member_level_code: price.member_level_code,
      pricing_style: this.currentPriceStyle,
      is_active: 1,
      price_breaks: [
        {
          price: price.price,
          quantity: +price.quantity,
          pre_excise: price.pre_excise,
          post_tax: price.post_tax
        }
      ]
    });
  }

  public editPricingRule(price: PriceModel) {
    const rulePos = findIndex(
      this.pricingRules,
      r => r.member_level_code === price.member_level_code
    );

    if (rulePos > -1) {
      const breaks = this.pricingRules[rulePos].price_breaks!;
      breaks[price.editingIndex!] = {
        price: price.price,
        quantity: +price.quantity,
        pre_excise: price.pre_excise,
        post_tax: price.post_tax
      };

      this.pricingRules[rulePos].price_breaks = breaks;
    }
  }

  public copyPrice() {
    this.$modals
      .load<Product>(PricePointCopyComponent, {
        size: "fit",
        positionY: "top",
        title: this.$t("copy_price_point").toString()
      })
      .then((response: Product) => this.copy(response))
      .catch(() => {
        // No action on close.
      });
  }

  public closeAndNotify() {
    this.$emit("setPrices", {
      rules: cloneDeep(this.pricingRules),
      priceType: this.priceType
    });
  }

  @Watch("prices", { deep: true })
  public priceChange() {
    this.pricingRules = [];
    this.pricingRules = cloneDeep([...this.prices]);
    this.setCurrentSet();
  }

  public async setCurrentSet() {
    const activeRule = this.getActiveRule();
    if (this.priceGroupSelectedId) {
      const memberLevelData = this.priceGroupAssigned;
      if (
        memberLevelData &&
        memberLevelData.rules &&
        memberLevelData.rules.length
      ) {
        memberLevelData.rules.map((item: PricingRule) => {
          if (item.id) {
            delete item.id;
          }
        });
        const pricingModel = memberLevelData.rules;
        if (activeRule !== undefined && !this.productSelected) {
          pricingModel.map(price => {
            if (price.member_level_code === activeRule!.member_level_code) {
              activeRule!.tax_category_id = price.tax_category_id;
              activeRule!.price_breaks = price.price_breaks;
            } else if (
              price.member_level_code === null &&
              activeRule!.member_level_code !== null
            ) {
              activeRule!.price_breaks = [];
            }
          });
        }
      }
    }
    if (activeRule) {
      this.currentPriceStyle = activeRule.pricing_style || null;
      this.currentTaxCategory = this.taxCategories.find(
        tc => tc.id === activeRule!.tax_category_id
      )!;
      this.setAndCalculeCurrentPricing(activeRule);
    } else {
      this.currentPriceStyle = null;
      this.currentPricingSet = [];
    }
  }

  public setAndCalculeCurrentPricing(activeRule: PricingRule) {
    const settingPriceType =
      this.priceType === "RETAIL_MEDICAL"
        ? "retail_pre_tax_pricing"
        : "wholesale_pre_tax_pricing";
    const goForward = !!this.currentRetailSettings[settingPriceType];
    activeRule.tax_category_id = this.currentTaxCategory.id!;
    activeRule.price_breaks = activeRule.price_breaks!.map(
      (pricebreak: Pricebreak) => {
        if (goForward) {
          return pricePointService.calculateForward(
            pricebreak,
            this.groupedTaxes
          );
        } else {
          return pricePointService.calculateBackwards(
            pricebreak,
            this.groupedTaxes,
            true
          );
        }
      }
    );
    this.currentPricingSet = activeRule.price_breaks!;
  }

  public taxChanged() {
    const activeRule = this.getActiveRule();
    if (activeRule) {
      this.setAndCalculeCurrentPricing(activeRule);
      const rulePos = findIndex(
        this.pricingRules,
        r =>
          r.member_level_code === activeRule.member_level_code &&
          r.pricing_type === activeRule.pricing_type
      );
      if (rulePos && this.currentTaxCategory.id!) {
        this.pricingRules[
          rulePos
        ].tax_category_id = this.currentTaxCategory.id!;
      }
      this.closeAndNotify();
    }
  }

  public showPopUp(event: string) {
    this.currentPriceStyle = event;
    this.$modals
      .loadConfirmation(
        {
          title: this.$t(" ").toString(),
          text: this.$t("pricing_style_text").toString(),
          acceptButton: "yes",
          cancelButton: "no"
        },
        {
          size: "fit",
          positionX: "center",
          positionY: "top",
          closable: false
        }
      )
      .then(async response => {
        if (response) {
          this.$emit("setPricingStyle", event);
          this.$emit("remove");
        } else {
          if (this.activeRuleData && !this.activeRuleData.pricing_style) {
            this.activeRuleData.pricing_style = event;
            this.currentPriceStyle = event;
          } else {
            this.currentPriceStyle = event !== "units" ? "units" : "weight";
          }
        }
      })
      .catch(error => {
        // Nothing to do
      });
  }
  public noPopUp(event: string) {
    return (this.currentPriceStyle = event);
  }
  public pricePointToggle(event: string) {
    if (this.currentPriceStyle !== event && this.currentPricingSet.length) {
      this.showPopUp(event);
    } else {
      this.noPopUp(event);
    }
  }

  public getActiveRule(): PricingRule | undefined {
    const activeRule = this.pricingRules.find(
      rule =>
        rule.member_level_code === this.currentMemberLevelCode &&
        !!rule.is_active
    );
    return activeRule;
  }

  protected async mounted() {
    this.model = {
      id: undefined,
      name: "",
      is_active: true,
      rule_type: "",
      pricing_style: "",
      group_type: "PRICING_GROUP",
      rules: []
    };
    const firstPrice = this.getActiveRule();
    this.currentTaxCategory =
      (firstPrice &&
        this.taxCategories.find(t => t.id === firstPrice.tax_category_id!)) ||
      this.taxCategories[0];
    if (firstPrice) {
      this.currentPriceStyle = firstPrice.pricing_style || null;
      this.setAndCalculeCurrentPricing(firstPrice);
    }
    this.pricingStyleItems = [
      {
        value: "weight",
        text: this.$t("by-weight").toString()
      },
      {
        value: "units",
        text: this.$t("by-count").toString()
      }
    ];
    this.priceChange();
  }
}
