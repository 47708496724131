import { EventBus } from "@/internal";
import { store } from "@/internal";
import { messagesService } from "@/services/messages.service";
import { Component, Prop, Vue } from "vue-property-decorator";
import { SelectImage } from "./SelectImage.enum";
import Template from "./SelectImage.template.vue";

@Component({
  mixins: [Template]
})
export default class SelectImageComponent extends Vue {
  public fileSelected: any = null;
  public fileToUpload: File | null = null;
  public isDragging: boolean = false;
  @Prop({ default: "" }) public defaultImage?: string;
  @Prop({ default: false }) public lazy!: boolean;
  @Prop({ default: false }) public disableBrowse!: boolean;
  @Prop({ default: false }) public modifyPermission!: boolean;
  @Prop({ default: false }) public brandModifyPermission!: boolean;
  public imageProduct?: string = "";
  public imageName?: string = "";
  public invalidFileName: boolean = false;

  public fileChangeEvent(imagen: any) {
    if (!imagen) {
      this.fileSelected = null;
      return;
    }
    let fileExtension = "";
    let fileSize = 0;
    // when user drag and drop file
    if (imagen.dataTransfer) {
      // only " alphanumeric,_ , - , ." characters are allowed in file name
      if (/([^a-zA-Z0-9_.\-])/.test(imagen.dataTransfer.files[0].name)) {
        this.invalidFileName = true;
      } else {
        this.invalidFileName = false;
      }
      // getting file extension
      fileExtension = imagen.dataTransfer.files[0].name.match(
        /\.([^\.]+)$/
      )![1];
      // getting file size in "mega bytes" by dividing with 1000000
      fileSize = imagen.dataTransfer.files[0].size / 1000000;
    } else {
      // when user uploads file
      if (/([^a-zA-Z0-9_.\-])/.test(imagen.target.files[0].name)) {
        this.invalidFileName = true;
      } else {
        this.invalidFileName = false;
      }
      fileExtension = imagen.target.files[0].name.match(/\.([^\.]+)$/)![1];
      fileSize = imagen.target.files[0].size / 1000000;
    }
    const invalidDocMsg: string | string[] = [];
    if (
      !store.getters["AuthModule/fileImageExtention"].includes(fileExtension)
    ) {
      invalidDocMsg.push(
        String(
          this.$t("security.invalid_file_extention", {
            fileExtention: store.getters["AuthModule/fileImageExtention"]
          })
        )
      );
    }
    if (fileSize > store.getters["AuthModule/fileUploadLimit"]) {
      invalidDocMsg.push(
        String(
          this.$t("security.invalid_file_size", {
            fileSize: store.getters["AuthModule/fileUploadLimit"]
          })
        )
      );
    }
    if (this.invalidFileName) {
      invalidDocMsg.push(String(this.$t("security.invalid_file_name")));
    }
    if (invalidDocMsg.length) {
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        invalidDocMsg,
        "error"
      );
    } else {
      this.fileToUpload = (imagen.target.files
        ? imagen.target.files[0]
        : imagen.dataTransfer!.files[0]) as File;
      const reader = new FileReader();
      reader.readAsDataURL(this.fileToUpload);
      reader.onloadend = () => {
        this.fileSelected = reader.result as string;
      };
      this.imageName = this.fileToUpload.name;
      this.$emit("fileSelected", this.fileToUpload);
    }
  }

  public resetImage() {
    this.imageProduct = "";
    this.fileSelected = "";
    this.$emit("fileSelected", SelectImage.DELETE_CODE);
  }

  public onDrop(event: DragEvent) {
    event.preventDefault();
    this.isDragging = false;
    if (!this.disableBrowse) {
      this.fileChangeEvent(event);
    } else {
      return;
    }
  }

  public onDragLeave(event: DragEvent) {
    event.preventDefault();
    this.isDragging = false;
  }

  public onDragOver(event: DragEvent) {
    event.preventDefault();
    this.isDragging = true;
  }

  public get imgSrc() {
    return this.fileSelected || this.imageProduct;
  }

  public mounted() {
    this.imageProduct = this.defaultImage;
    EventBus.$on("resetImage", () => {
      this.fileSelected = "";
      this.defaultImage = "";
    });

    if (this.lazy) {
      const unwatch = this.$watch("defaultImage", () => {
        this.imageProduct = this.defaultImage;
        unwatch();
      });
    }
  }
}
