import { Product } from "@/interfaces/product";
import { WmProductModel } from "@/interfaces/WeedmapsData";
import cloneDeep from "lodash/cloneDeep";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import NonVerifiedFromComponent from "./nonVerifiedForm/nonVerifiedForm.component";
import VerifiedFormComponent from "./verifiedForm/verifiedForm.component";
import Template from "./WeedmapsInfo.template.vue";

const WeedMapsProduct = {
  isVerified: true,
  isloaded: false,
  verifiedModel: { weedmaps_product: null, weedmaps_product_variant: null },
  nonVerifiedModel: {
    name: null,
    attribute: null,
    unit: null,
    tags: []
  },
  changed: false
};
@Component({
  mixins: [Template],
  components: {
    VerifiedFormComponent,
    NonVerifiedFromComponent
  }
})
export default class WeedmapsInfoComponent extends Vue {
  @Prop({ required: true })
  public product!: Product;
  @Prop({ default: false })
  public onDisplay!: boolean;
  @Prop({ required: true })
  public value!: WmProductModel;

  public emitModel() {
    this.model.changed = true;
    this.$emit("input", this.model);
  }

  public get model() {
    return cloneDeep(this.value);
  }

  public verifiedChanged(val: boolean) {
    if (val) {
      this.model.nonVerifiedModel = WeedMapsProduct.nonVerifiedModel;
    } else {
      this.model.verifiedModel = WeedMapsProduct.verifiedModel;
    }
    this.model.isVerified = val;
    this.emitModel();
  }
  @Watch("onDisplay")
  public displayChanged() {
    if (this.onDisplay) {
      this.model.nonVerifiedModel.name = this.product.name;
    }
  }
}
