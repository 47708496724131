import SelectImageComponent from "@/components/sharedComponents/selectImage/SelectImage.component";
import { Product } from "@/interfaces/product";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./PictureProduct.template.vue";

/**
 * product basic info form component
 */
@Component({
  mixins: [Template],
  components: {
    SelectImageComponent
  }
})
export default class PictureProdcutComponent extends Vue {
  @Prop({ required: true })
  protected product!: Product;

  public fileSelected(image: File) {
    this.product.image = image;
  }

  public get mainImageSrc() {
    return (
      (this.product.media &&
        this.product.media[0] &&
        this.product.media[0].media_medium_url) ||
      ""
    );
  }
}
