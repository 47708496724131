import { ResetService } from "@/decorators/resetService.decorator";
import { ProductBatch } from "@/interfaces/batch";
import { Location } from "@/interfaces/location";
import { Product } from "@/interfaces/product";
import { BatchesTableMetadata } from "@/metadata/product";
import { productActionService } from "@/services/product.actions.service";
import { productService } from "@/services/product.service";
import { Callback } from "@/types/types";
import {
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination
} from "helix-vue-components";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./BatchesProduct.template.vue";

const namespace = "BatchModule";
/**
 * product basic info form component
 */
@Component({
  mixins: [Template],
  components: {
    TableComponent
  }
})
@ResetService(productService)
export default class BatchesProductComponent extends Vue {
  @Prop({ required: true })
  public product!: Product;
  @Prop({ required: true })
  public active!: boolean;
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;
  @Getter("productBatchesPagination", { namespace })
  public pagination!: TablePagination;
  @Getter("productBatches", { namespace })
  public batches!: ProductBatch[];
  @Getter("loading", { namespace })
  public loading!: boolean;
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  @Action("loadProductBatches", { namespace })
  public loadProductBatches!: Callback;

  public rowActions: TableAction[] = [];
  public headers: TableHeader[] = [];
  public showQuantityZero = false;
  public sort: string | null = null;
  public totalInStore = "--";
  public loadingQty = false;

  public refreshBatches() {
    if (this.active) {
      this.callProduct();
    }
  }

  public callProduct(pagination?: TablePagination) {
    this.loadProductBatches({
      productSKU: this.product.sku,
      showZeros: this.showQuantityZero,
      pagination,
      sort: this.sort
    });
  }

  public get getBatches() {
    this.batches.map(batch => {
      if (this.bioTrackTraceEnabled && batch.biotrack_traceability_id) {
        batch.batchUid = batch.batch_uid;
        batch.batch_uid = batch.biotrack_traceability_id;
      } else {
        batch.batchUid = batch.batch_uid;
        return batch.batch_uid;
      }
    });
    return this.batches;
  }

  protected async filter(headers: TableHeader) {
    const h = { ...headers };

    if (h.value === "total_quantity") {
      h.value = "quantity";
    }

    this.sort = productService.getSortQuery(h);
    this.callProduct(this.pagination);
  }

  protected changePagination(pagination: TablePagination) {
    this.callProduct(pagination);
  }

  protected initBatches() {
    this.showQuantityZero = false;
    this.callProduct();
  }

  protected async getTotalQuantity() {
    this.loadingQty = true;
    const totalQty = await productService.getProductTotalQuantity(
      this.product.sku
    );
    if (totalQty) {
      this.totalInStore = `${totalQty.total_in_store} ${this.product.unit}`;
    } else {
      this.totalInStore = "--";
    }
    this.loadingQty = false;
  }

  protected async mounted() {
    this.headers = BatchesTableMetadata;
    this.rowActions = await productActionService.getBatchViewRowActions();
    const unwatch = this.$watch("active", () => {
      if (this.active) {
        this.initBatches();
        this.getTotalQuantity();
        unwatch();
      }
    });
  }
}
