import { Location } from "@/interfaces/location";
import { PriceModel, Product } from "@/interfaces/product";
import { ProductCategory } from "@/interfaces/productCategoy";
import { User } from "@/interfaces/user";
import { productCategoryService } from "@/services/productCategory.service";
import { Callback } from "@/types/types";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./DataSharing.template.vue";

interface DefaultSharingType {
  product_category_id?: string | null;
  location_id?: number | null;
  price?: number | null;
  tax_id?: number | null;
}

/**
 * namespace to dispatch vuex actions and getters
 * @const string namespace
 */
const namespace: string = "LocationModule";

/**
 * product basic info form component
 */
@Component({
  mixins: [Template]
})
export default class DataSharingComponent extends Vue {
  @Getter("locations", { namespace })
  public locations!: Location[] | null;

  @Action("loadLocations", { namespace })
  public loadLocationsAction!: Callback;

  public defaultSharing: DefaultSharingType = {
    product_category_id: null,
    location_id: null
  };

  @Prop({ required: true })
  protected product!: Product;

  @Getter("user", { namespace: "AuthModule" })
  protected user!: User;

  protected categories: ProductCategory[][] = [];

  protected isLoadingCategories: boolean[] = [];

  protected searchCategoryField: string[] = [];

  protected currentEditing: number | null = null;

  public defaultLocationProduct(): object[] {
    let location!: object;
    // if (this.product.prices) {
    //   location = {
    //     product_category_id: this.product.product_category_id,
    //     location_id: this.user.settings.current_location_id,
    //     price: this.product.prices[0] ? this.product.prices[0].price : 0,
    //     tax_id: this.product.prices[0].tax_category_id
    //   };
    // } else {
    location = {
      product_category_id: this.product.product_category_id,
      location_id: this.user.settings.current_location_id,
      price: 0,
      tax_id: null
    };
    // }
    return [location];
  }

  public addNewLocation() {
    const location = { ...this.defaultSharing };
    location.product_category_id = this.product.locationProducts![0].product_category_id;
    location.price = this.product.locationProducts![0].price;
    location.tax_id = this.product.locationProducts![0].tax_id;
    this.product.locationProducts!.push(location);
    this.categories.push([]);
    this.isLoadingCategories.push(false);
    this.$forceUpdate();
  }

  public shareWithAll() {
    this.product.locationProducts =
      this.product.locationProducts || this.defaultLocationProduct();
    this.locationItems.forEach((location: Location) => {
      const exist = this.product.locationProducts!.find(locationSaved => {
        return locationSaved.location_id === location.id;
      });
      if (!exist) {
        this.addNewLocation();
      }
    });
  }

  public setCurrentLocation(currentLocationIndex: number) {
    this.currentEditing = currentLocationIndex;
    this.categories[currentLocationIndex] = [];
  }

  public remove(i: number) {
    this.product.locationProducts!.splice(i, 1);
    this.$forceUpdate();
  }

  @Watch("product.product_category_id", { immediate: false, deep: true })
  public setDefaultProductCategory(value: number) {
    if (
      value &&
      this.product.locationProducts &&
      this.product.locationProducts.length > 0
    ) {
      // @ts-ignore
      this.product.locationProducts[0].product_category_id = value;
    }
  }

  @Watch("product.prices", { immediate: false, deep: true })
  public setDefaultPrice(value: PriceModel[]) {
    if (
      !value ||
      !this.product.locationProducts ||
      value.length === 0 ||
      this.product.locationProducts.length === 0
    ) {
      return;
    }
    this.product.locationProducts[0].price = value[0].price;
    this.product.locationProducts[0].tax_id = value[0].tax_category_id;
  }

  @Watch("searchCategoryField", { immediate: false, deep: true })
  public async findCategories(
    categoryName: string[],
    categoryNameOld: string[]
  ) {
    if (
      this.currentEditing === null ||
      this.categories[this.currentEditing].length > 0
    ) {
      return;
    }
    this.isLoadingCategories[this.currentEditing] = true;
    productCategoryService
      .findByLocationAndNameContains(
        this.product.locationProducts![this.currentEditing!].location_id!,
        categoryName[this.currentEditing]
      )
      .then(categories => {
        // @ts-ignore
        this.categories[this.currentEditing] = categories;
      })
      .finally(() => {
        this.$forceUpdate();
        this.isLoadingCategories[this.currentEditing!] = false;
      });
  }

  @Watch("product.location_products", { immediate: false, deep: true })
  public async initCategory() {
    if (this.product.locationProducts!.length > 0) {
      this.categories = [];
      this.product.locationProducts!.forEach(async (location, index) => {
        const categories = await productCategoryService.findByLocationAndNameContains(
          // @ts-ignore
          location.location_id,
          ""
        );
        this.categories.push(categories);
        this.$forceUpdate();
      });
    }
  }

  get locationItems() {
    return this.locations!.filter((location: Location) => {
      return (
        // @ts-ignore
        location.id !== parseInt(this.user.settings.current_location_id, 10)
      );
    });
  }

  protected async mounted() {
    if (!this.locations || !this.locations.length) {
      this.loadLocationsAction();
    }
  }
}
