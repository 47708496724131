import PricePointStructureComponent from "@/components/inventory/pricePoint/pricePointStructure/PricePointStructure.component";
import { EventBus } from "@/event-bus";
import { Location } from "@/interfaces/location";
import { PriceGroupInterface } from "@/interfaces/priceGroups";
import {
  Pricebreak,
  PricingRule,
  Product,
  ProductCost,
  ProductPricing
} from "@/interfaces/product";
import { RetailSettings } from "@/interfaces/retailSettings";
import { TaxCategory } from "@/interfaces/taxCategory";
import { priceGroupService } from "@/services/priceGroup.service";
import { pricePointService } from "@/services/pricePoint.service";
import { productService } from "@/services/product.service";
import { taxCategoryService } from "@/services/taxCategory.service";
import cloneDeep from "lodash/cloneDeep";
import { Validator } from "vee-validate";
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./PricingDiscount.template.vue";

/**
 * product basic info form component
 */
@Component({
  mixins: [Template],
  components: {
    PricePointStructureComponent
  }
})
export default class PricingDiscountComponent extends Vue {
  @Prop({ required: true })
  public active!: boolean;
  /**
   * model to save
   * @var Product product
   */
  @Prop({ required: true })
  public product!: Product;

  @Prop({ default: false })
  public isModifiableProducts!: boolean;

  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;

  @Inject("$validator") public $validator!: Validator;
  public header: ProductCost = {
    current_total_cost: 0,
    average_cost_per_unit: 0,
    total_potential_profit: 0
  };
  public renderPrices = false;
  // Added 2 new boolean values to filter out the conditions
  public isPricegroupActive!: boolean;
  public isCustomActive!: boolean;
  public pricingModel: PricingRule[] = [];
  public hasPrices = false;
  public renderReady = false;
  public priceGroupItems: PriceGroupInterface[] = [];
  public loadingPriceGroup = false;
  public productPricing!: ProductPricing | null;
  public showPriceGroupDropdown = false;
  public isUsableWeight = true;
  public productSelected: boolean = true;
  public priceGroupAssigned!: PriceGroupInterface;
  protected pricePoint: string = "RECREATIONAL";
  protected pricingStyle!: string | undefined;
  protected taxCategories!: TaxCategory[];

  public validateNumber(event: KeyboardEvent) {
    if (event.key !== ".") {
      if (isNaN(+event.key)) {
        event.preventDefault();
      }
    }
    return true;
  }

  public emitPricing(changed = true) {
    this.$emit("updatePricing", {
      rules: this.pricingModel,
      changed,
      hasPrices: this.hasPrices
    });
  }
  public autoSelectUsableWeight(pricingStyle?: string) {
    if (!this.product.requires_weighing && this.product.marijuana) {
      if (pricingStyle === "units") {
        this.product.price_settings.price_point_by_usable_weight = 0;
      } else if (pricingStyle === "weight") {
        this.product.price_settings.price_point_by_usable_weight = 1;
      }
      this.isUsableWeight = false;
    }
    this.pricingStyle = pricingStyle;
  }

  public async assignSelectedPriceGroupRules() {
    if (
      (this.product.price_settings.pricing_type === "PRICE_GROUP" &&
        this.product.price_settings.uid &&
        this.isPricegroupActive &&
        !this.isCustomActive) ||
      (this.product.price_settings.pricing_type === "PRICE_GROUP" &&
        this.product.price_settings.uid &&
        !this.isPricegroupActive &&
        this.isCustomActive)
    ) {
      this.pricingModel = [];
      if (this.product.price_settings.uid) {
        const selectedItemIndex = this.priceGroupItems.findIndex(
          item => item.uid === this.product.price_settings.uid
        );
        const priceGroup = this.priceGroupItems[selectedItemIndex];
        this.priceGroupAssigned = priceGroup;
        this.product.price_settings.pricing_group_id =
          priceGroup.pricing_group_id;
        this.product.price_settings.price_group = priceGroup.name;
        if (
          priceGroup &&
          (priceGroup.pricing_style ||
            priceGroup.pricing_style !== null ||
            priceGroup.pricing_style !== undefined)
        ) {
          this.autoSelectUsableWeight(priceGroup.pricing_style);
        }
        if (priceGroup && priceGroup.rules && priceGroup.rules.length) {
          priceGroup.rules.map((item: PricingRule) => {
            if (item.id) {
              delete item.id;
            }
          });
          this.pricingModel = priceGroup.rules;
        } else {
          const productPricingModel =
            cloneDeep(this.productPricing && this.productPricing.rules) || [];
          productPricingModel.map(items => {
            items.price_breaks = [];
          });
          this.pricingModel = productPricingModel;
        }
      }
      this.hasPrices =
        this.productPricing &&
        this.productPricing.rules &&
        this.productPricing.rules.length
          ? true
          : false;
      this.emitPricing(true);
      // Custom Price is active then No price groups are shown
    } else if (
      this.product.price_settings.pricing_type === "PRICE_GROUP" &&
      this.isCustomActive
    ) {
      this.isPricegroupActive = false;
      this.pricingModel = [];
      // Price groups are active then No custom prices are shown
    } else if (
      this.product.price_settings.pricing_type === "CUSTOM" &&
      this.isPricegroupActive
    ) {
      this.isCustomActive = false;
      this.product.price_settings.price_group = null;
      this.product.price_settings.pricing_group_id = null;
      this.product.price_settings.uid = null;
      this.pricingModel = [];
    } else if (
      this.product.price_settings.pricing_type === "CUSTOM" &&
      this.isCustomActive
    ) {
      this.isPricegroupActive = false;
      this.product.price_settings.price_group = null;
      this.product.price_settings.pricing_group_id = null;
      this.product.price_settings.uid = null;
      if (this.$route.name === "products-add") {
        this.setProductPricingModel();
      }
    }
    this.showPriceGroupDropdown =
      this.product.price_settings.pricing_type === "PRICE_GROUP" ? true : false;
    // On cahnging Price Type reset member level
    EventBus.$emit("onChangePriceRules", true);
  }

  public async getPriceGroupsList() {
    this.loadingPriceGroup = true;
    const items = await priceGroupService.getPriceGroupList();
    this.priceGroupItems = items.data;
  }

  public async setProductPricingModel() {
    if (!this.productPricing) {
      this.productPricing = this.product.sku
        ? await productService.getProductPricing(this.product.sku)
        : null;
    }
    this.pricingModel =
      cloneDeep(this.productPricing && this.productPricing.rules) || [];
    this.hasPrices = !!this.pricingModel.length;
    this.renderPrices = true;

    let priceGroupIndex = this.priceGroupItems.findIndex(
      item => item.uid === this.product.price_settings.uid
    );
    if (
      priceGroupIndex === -1 &&
      this.product.price_settings.pricing_type === "PRICE_GROUP"
    ) {
      priceGroupIndex = this.priceGroupItems.findIndex(
        item =>
          String(item.name) === String(this.product.price_settings.price_group)
      );
      if (priceGroupIndex !== -1) {
        const priceGroup = this.priceGroupItems[priceGroupIndex];
        this.product.price_settings.uid = priceGroup.uid;

        this.pricingModel = [];

        if (priceGroup && priceGroup.rules && priceGroup.rules.length) {
          priceGroup.rules.map((item: PricingRule) => {
            if (item.id) {
              delete item.id;
            }
          });
          this.pricingModel = priceGroup.rules;
        } else {
          const productPricingModel =
            cloneDeep(this.productPricing && this.productPricing.rules) || [];
          productPricingModel.map(data => {
            data.price_breaks = [];
          });
          this.pricingModel = productPricingModel;
        }
        await productService.setProductPricing(
          this.product.sku,
          this.pricingModel,
          this.hasPrices
        );
      }
    }
    if (
      this.product.price_settings.uid &&
      this.product.price_settings.pricing_type === "PRICE_GROUP"
    ) {
      const index = this.priceGroupItems.findIndex(
        group => group.id === this.product.price_settings.uid
      );

      if (
        index !== -1 &&
        this.priceGroupItems[index] &&
        (this.priceGroupItems[index].pricing_style ||
          this.priceGroupItems[index].pricing_style !== null ||
          this.priceGroupItems[index].pricing_style !== undefined)
      ) {
        this.autoSelectUsableWeight(this.priceGroupItems[index].pricing_style);
      }
    }
    this.loadingPriceGroup = false;
    this.emitPricing(false);
  }

  protected async mounted() {
    // Assigning boolean values depending on Custom or Price group condition
    if (
      !this.product.price_settings.pricing_type ||
      this.product.price_settings.pricing_type === "CUSTOM"
    ) {
      this.product.price_settings.pricing_type = "CUSTOM";
      this.isPricegroupActive = false;
      this.isCustomActive = true;
    } else {
      this.isPricegroupActive = true;
      this.isCustomActive = false;
    }
    this.assignSelectedPriceGroupRules();
    this.showPriceGroupDropdown =
      this.product.price_settings.pricing_type === "PRICE_GROUP" ? true : false;

    this.getPriceGroupsList();
    this.renderReady = true;
    if (this.product.sku) {
      productService.getProductTotal(this.product.sku).then(response => {
        if (response) {
          this.header = response;
        }
      });
    }

    const unwatch = this.$watch("active", async () => {
      if (this.active) {
        this.setProductPricingModel();
        unwatch();
      }
    });
  }
}
