import { AxiosResponse, Method } from "axios";

import { messagesService } from "./messages.service";

import { SharingProductType } from "@/interfaces/sharing";

import { PricingRule, Product, ProductBasicInfo } from "@/interfaces/product";
import Vue from "vue";
import LocationShareableService from "./LocationShareable.service";

class SharedProductService {
  public async put(sku: string, type: string, locationId: number[]) {
    try {
      const payload = {
        sku,
        type,
        location_ids: locationId
      };
      const response: AxiosResponse = await Vue.axios({
        method: "put",
        url: `catalog/product_shared_tool/`,
        data: payload
      });
      return response.data.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return [];
    }
  }
  public async setProductPricing(rules: PricingRule[], method: Method) {
    try {
      const payload = {
        rule_set: rules
      };

      const response: AxiosResponse = await Vue.axios({
        method,
        url: `price/data_shared_rule_set`,
        data: payload
      });

      return response.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return null;
    }
  }

  public async onSaveSharedProduct(
    product: SharingProductType,
    sku: string,
    productBasicInfoData: ProductBasicInfo
  ) {
    try {
      const payload: { [key: string]: any } = {
        batch_type: product.batch_types.map(item => {
          return {
            location_id: item.location_id,
            ...item.productName
          };
        }),
        batch_types: product.batch_types.map(item => {
          return {
            batch_type_id: item.uid,
            location_id: item.location_id
          };
        }),
        category: product.product_categories.map(item => {
          return {
            location_id: item.location_id,
            ...item.productName
          };
        }),
        product_categories: product.product_categories.map(item => {
          return {
            product_category_id: item.uid,
            location_id: item.location_id
          };
        }),
        strain: product.strains.map(item => {
          return {
            location_id: item.location_id,
            ...item.productName
          };
        }),
        strains: product.strains.map(item => {
          return {
            strain_id: item.uid,
            location_id: item.location_id
          };
        }),
        vendor: product.vendor.map(item => {
          return {
            location_id: item.location_id,
            ...item.productName
          };
        }),
        vendors: product.vendor.map(item => {
          return {
            vendor_id: item.uid,
            location_id: item.location_id
          };
        }),
        brand: product.brands.map(item => {
          return {
            location_id: item.location_id,
            ...item.productName
          };
        }),
        brands: product.brands.map(item => {
          return {
            brand_id: item.uid,
            location_id: item.location_id
          };
        }),
        price_settings: product.price_settings,
        ...productBasicInfoData
      };

      for (const i in payload) {
        if (!payload[i] || typeof payload[i] !== "object") {
          continue;
        }

        if (Object.keys(payload[i]).length === 0) {
          delete payload[i];
        }
      }
      const skuSplit = sku.split(":");
      const skuId = skuSplit[0];
      const response: AxiosResponse = await Vue.axios({
        method: "put",
        url: `catalog/products/concrete/${skuId}`,
        data: payload
      });
      return response.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
    }
  }

  public async getAll(
    sku: string,
    type: string,
    locationId: Array<number | null>
  ) {
    try {
      const payload = {
        location_ids: locationId
      };
      const response: AxiosResponse = await Vue.axios({
        method: "put",
        url: `catalog/product_shared_warnings/${sku}/${type}`,
        data: payload
      });
      return response.data.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return [];
    }
  }
}

export const sharedProductService = new SharedProductService();
