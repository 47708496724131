import BasicInfoComponent from "@/components/inventory/ProductForm/BasicInfo/BasicInfo.component";
import BatchesProductComponent from "@/components/inventory/ProductForm/BatchesProduct/BatchesProduct.component";
import DataSharingComponent from "@/components/inventory/ProductForm/DataSharing/DataSharing.component";
import PackagingDetailComponent from "@/components/inventory/ProductForm/PackagingDetail/PackagingDetail.component";
import PictureProductComponent from "@/components/inventory/ProductForm/PictureProduct/PictureProduct.component";
import PrescriptionDetailsComponent from "@/components/inventory/ProductForm/PrescriptionDetails/PrescriptionDetails.component";
import PricingDiscountComponent from "@/components/inventory/ProductForm/PricingDiscount/PricingDiscount.component";
import { policyList } from "@/enums/permissions";
import { Location } from "@/interfaces/location";
import { Modal } from "@/interfaces/modal";
import { PusherNotification } from "@/interfaces/notification";
import { defaultPrescriptionDetails } from "@/interfaces/prescriptionDetails";
import {
  Pricebreak,
  PricingRule,
  Product,
  productDefault
} from "@/interfaces/product";
import { RetailSettings } from "@/interfaces/retailSettings";
import { TaxCategory } from "@/interfaces/taxCategory";
import { ProductMetrcItem } from "@/interfaces/traceability";
import { User } from "@/interfaces/user";
import { WmIntegratedProduct, WmProductModel } from "@/interfaces/WeedmapsData";
import { EventBus, pusherEvents } from "@/internal";
import { ChangesNotificationService } from "@/plugins/changes-notifications/changes-notification.service";
import { metrcEnabled, weedMapsEnabled } from "@/router.utils";
import { messagesService } from "@/services/messages.service";
import { pricePointService } from "@/services/pricePoint.service";
import { productService } from "@/services/product.service";
import { taxCategoryService } from "@/services/taxCategory.service";
import { traceabilityService } from "@/services/traceability.service";
import { weedmapsInfoService } from "@/services/weedmapsInfo.service";
import { Callback, PageNavAction } from "@/types/types";
import { BooleanCheck } from "@/types/types";
import cloneDeep from "lodash/cloneDeep";
import omit from "lodash/omit";
import { Validator } from "vee-validate";
import { Component, Inject, Provide, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./ProductForm.template.vue";
import ProductMetrcInfoComponent from "./ProductMetrcInfo/ProductMetrcInfo.component";
import WeedmapsInfoComponent from "./WeedmapsInfo/WeedmapsInfo.component";

const namespace: string = "ProductModule";
const WeedMapsProduct = {
  isVerified: true,
  isloaded: false,
  verifiedModel: { weedmaps_product: null, weedmaps_product_variant: null },
  nonVerifiedModel: {
    name: null,
    attribute: null,
    unit: null,
    tags: []
  },
  changed: false
};
enum ProductTabs {
  batchAssigned = "batch-assigned",
  basicInfo = "basic-info",
  pricingDiscount = "pricing-discount",
  packageDetail = "packaging-detail",
  pictureProduct = "picture-product",
  weedmapsInfo = "weedmaps-info",
  productMetrcInfo = "metrc-info",
  prescriptionDetails = "prescription-details"
}

@Component({
  mixins: [Template],
  components: {
    BasicInfoComponent,
    DataSharingComponent,
    PricingDiscountComponent,
    PackagingDetailComponent,
    PictureProductComponent,
    BatchesProductComponent,
    WeedmapsInfoComponent,
    ProductMetrcInfoComponent,
    PrescriptionDetailsComponent
  },
  inject: ["$changes"]
})
export default class ProductFormComponent extends Vue {
  public disabled = true;
  @Inject("$validator") public $validator!: Validator;

  @Provide()
  public validator = this.$validator;

  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;

  public activeTab: string | null = null;
  public isSaving = false;
  public isLoading = false;
  public savePricing = false;
  public hasPrices = false;
  public pricingRules: PricingRule[] = [];
  public productTabs = ProductTabs;
  public errorGetBrandWM = false;
  public WmIntegrationData: WmIntegratedProduct | null = null;
  public weedmapsModel: WmProductModel = cloneDeep(WeedMapsProduct);
  public hasWmIntegration = weedMapsEnabled();
  public productMetrcInfo: ProductMetrcItem = {
    biotrack_sku: null,
    metrc_id: null,
    isNewItem: true
  };
  public hasMetrc = metrcEnabled();
  public isPharmacyEnabled = false;
  public isModifiableProducts: boolean = true;

  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public currentRetailSettings!: RetailSettings;

  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;

  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;
  protected taxCategories!: TaxCategory[];

  protected modalData: Modal = { title: null, width: "603px", height: "200px" };

  @Action("errorRedirect", { namespace: "RouterModule" })
  protected errorRedirect!: Callback;

  protected product: Product = { ...productDefault };
  protected changes$!: ChangesNotificationService;

  @Getter("user", { namespace: "AuthModule" })
  protected user!: User;
  public get disableWeedMaps() {
    if (!this.product.brand_id) {
      this.weedmapsModel = cloneDeep(WeedMapsProduct);
      return true;
    }
    return this.errorGetBrandWM;
  }
  public async mounted() {
    if (this.$route.params.id) {
      this.isModifiableProducts = this.hasPermission(policyList.modifyProducts);
    }
    this.isPharmacyEnabled = !!this.currentRetailSettings.pharmacy_mode_enabled;
    this.setPageNav({
      title: "products",
      isLoading: () => this.isLoading || this.isSaving,
      rightActions: {
        generalActions: () => [
          {
            icon: "fal fa-check",
            id: "btn_save",
            action: () => this.save(true),
            vuetifyProps: () => ({
              loading: this.isSaving,
              fab: true,
              small: true
            })
          },
          {
            icon: "fal fa-times",
            id: "btn_cancel",
            action: this.cancel,
            vuetifyProps: () => ({
              loading: this.isSaving,
              fab: true,
              small: true
            })
          }
        ]
      }
    });
    if (this.currentRetailSettings.retail_pre_tax_pricing) {
      this.taxCategories = await taxCategoryService.getAll();
    }
  }
  public groupedTaxes(taxId: number | null) {
    const currentTax = this.taxCategories.find(
      (tax: TaxCategory) => tax.id === taxId
    );
    return pricePointService.getGroupedTaxes(currentTax!.taxes!);
  }

  public async save(share: boolean = false) {
    if (!this.hasPermission(policyList.modifyProducts) && this.product.sku) {
      this.cancel();
      return;
    }
    const isValid = await this.$validator.validateAll();
    if (
      this.currentLocation.state &&
      this.currentLocation.state.code === "CT" &&
      !this.product.marijuana &&
      this.product.ndc_number
    ) {
      this.product.ndc_number = "";
    }
    if (isValid) {
      this.isSaving = true;
      this.product.shareable = share;
      this.product = omit(this.product, ["cost_per_unit"]) as Product;
      this.product.unit = (this.product.requires_weighing && "g") || "u";
      this.product.price_settings.price_point_by_usable_weight =
        (this.product.marijuana &&
          this.product.price_settings.price_point_by_usable_weight) ||
        0;
      if (this.product.requires_weighing || !this.product.marijuana) {
        this.product.usable_weight_value = null;
        this.product.usable_weight_unit = null;
      }

      this.product.metrc_weighable = this.product.requires_weighing
        ? true
        : this.product.metrc_weighable;

      try {
        const saved = await productService.save(this.product);
        messagesService.renderSuccessMessage("product_saved");
        // WM Integration
        if (saved && this.weedmapsModel.changed) {
          const integrated = await weedmapsInfoService.integrateProduct(
            { ...saved, price_settings: this.product.price_settings },
            this.weedmapsModel
          );
          // Associate TAGs for non verified
          if (integrated && !this.weedmapsModel.isVerified) {
            await weedmapsInfoService.assignTagsToProduct(
              saved,
              this.weedmapsModel.nonVerifiedModel.tags
            );
          }
        }
        // Prices
        if (this.savePricing && saved) {
          if (this.currentRetailSettings.retail_pre_tax_pricing) {
            this.setAndCalculeCurrentPricingDefault();
          }
          await productService.setProductPricing(
            saved.sku,
            this.pricingRules,
            this.hasPrices
          );
        }
        // metrc
        if (this.productMetrcInfo && this.productMetrcInfo.hasChanged) {
          if (
            !this.productMetrcInfo.metrc_id &&
            !this.productMetrcInfo.isNewItem
          ) {
            await traceabilityService.unlinkProduct(this.product.sku);
          } else if (this.productMetrcInfo.metrc_id) {
            await traceabilityService.setProductMetrcMappedItem({
              biotrack_sku: saved.sku,
              metrc_id: this.productMetrcInfo.metrc_id,
              isNewItem: this.productMetrcInfo.isNewItem
            });
          }
        }

        this.$router.back();
      } catch (e) {
        messagesService.renderErrorMessage(e);
      } finally {
        this.isSaving = false;
      }
    } else {
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        String(
          this.$t("batch_transfer_manager_module.messages.missing_form_data")
        ),
        "error"
      );
    }
  }

  public cancel() {
    this.$router.back();
  }

  public setDisabled(value: boolean) {
    this.disabled = value;
  }

  public updatePricing(data: {
    rules: PricingRule[];
    changed: boolean;
    hasPrices: boolean;
  }) {
    this.pricingRules = data.rules;
    this.savePricing = data.changed;
    this.hasPrices = data.hasPrices;
  }
  protected setAndCalculeCurrentPricingDefault() {
    this.pricingRules.map(price => {
      const settingPriceType =
        price.pricing_type === "RETAIL_MEDICAL"
          ? "retail_pre_tax_pricing"
          : "wholesale_pre_tax_pricing";
      const goForward = !!this.currentRetailSettings[settingPriceType];
      if (price.price_breaks) {
        price.price_breaks!.map((pricebreak: Pricebreak) => {
          if (!(pricebreak.post_tax || pricebreak.pre_excise)) {
            if (goForward) {
              return pricePointService.calculateForward(
                pricebreak,
                this.groupedTaxes(price.tax_category_id!)
              );
            } else {
              return pricePointService.calculateBackwards(
                pricebreak,
                this.groupedTaxes(price.tax_category_id!),
                true
              );
            }
          }
        });
      }
    });
  }

  protected async loadProduct() {
    try {
      const productResponse = await productService.findBySkuId(
        this.$route.params.id,
        {
          load_has_batches: true
        }
      );
      if (!productResponse.prescription_detail) {
        productResponse.prescription_detail = defaultPrescriptionDetails;
      }
      if (productResponse.prescription_detail!.quantity) {
        productResponse.prescription_detail.quantity = +productResponse.prescription_detail!
          .quantity!;
      }
      if (productResponse.prescription_detail.days_supply!) {
        productResponse.prescription_detail!.days_supply = +productResponse.prescription_detail!
          .days_supply!;
      }
      if (productResponse!.nutrition_calories_per_serving!) {
        productResponse!.nutrition_calories_per_serving = +productResponse!
          .nutrition_calories_per_serving!;
      }
      if (productResponse!.nutrition_total_calories) {
        productResponse!.nutrition_total_calories = +productResponse!
          .nutrition_total_calories!;
      }
      this.product = cloneDeep(productResponse);
    } catch (e) {
      return e;
    }
  }

  protected setWmModel(integration: WmIntegratedProduct) {
    this.weedmapsModel.isVerified = !!integration.is_master;
    this.weedmapsModel.isloaded = true;

    if (this.weedmapsModel.isVerified) {
      this.weedmapsModel.verifiedModel = {
        weedmaps_product: {
          weedmaps_product_id: integration.weedmaps_product_id,
          name: integration.name,
          weedmaps_brand_id: integration.brand.weedmaps_brand_id,
          wmsin: integration.wmsin,
          is_master: !!integration.is_master,
          tags: integration.tags,
          product_variants: [
            {
              type: "product_variants",
              id: integration.variant.id
            }
          ]
        },
        weedmaps_product_variant: {
          weedmaps_product_variant_id:
            integration.variant.weedmaps_product_variant_id,
          attribute: "",
          value:
            (integration.variant.value && integration.variant.value.name) ||
            "default"
        }
      };
    } else {
      this.weedmapsModel.nonVerifiedModel = {
        name: integration.name,
        attribute:
          (integration.variant.value &&
            integration.variant.value.const_attribute) ||
          null,
        unit:
          (integration.variant.value && integration.variant.value.const_unit) ||
          null,
        tags: integration.tags
      };
    }
  }

  protected async created() {
    if (this.$route.params.id) {
      this.isLoading = true;

      const [error, WmIntegrationData] = await Promise.all([
        this.loadProduct(),
        this.hasWmIntegration
          ? weedmapsInfoService.getProduct(this.$route.params.id)
          : null
      ]);

      this.productMetrcInfo.biotrack_sku = this.$route.params.id;

      this.isLoading = false;
      if (error) {
        this.setDisabled(false);
        this.errorRedirect({ error });
      }

      if (WmIntegrationData) {
        this.WmIntegrationData = WmIntegrationData;
        this.setWmModel(WmIntegrationData);
      }
    } else {
      this.product = cloneDeep({ ...productDefault });
      this.weedmapsModel = cloneDeep(WeedMapsProduct);
    }
    EventBus.$on("detailsBatches.products", () => {
      this.activeTab = "batch-assigned";
    });
    EventBus.$on("errorBrandWeedMaps", (value: boolean) => {
      this.errorGetBrandWM = value;
    });
    this.$changes.watch(
      pusherEvents.productTouched,
      async () => {
        this.isLoading = true;
        await this.loadProduct();
        this.product = { ...productDefault };
        this.isLoading = false;
      },
      (data: PusherNotification) => {
        if (this.product && this.product.id) {
          return !!data.message.find(n => n.item_id === this.product.id);
        }

        return false;
      }
    );
  }
}
