import { WmTag } from "@/interfaces/WeedmapsData";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import toArray from "lodash/toArray";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./categorySelector.template.vue";

@Component({
  name: "CategorySelectorComponent",
  mixins: [Template]
})
export class CategorySelectorComponent extends Vue {
  @Prop({ default: [] }) public categories!: WmTag[];
  @Prop({ required: true }) public selectedCategories!: WmTag[];
  @Prop({ default: false }) public start!: boolean;
  public newCategories: WmTag[] = [];
  public orderedCategories: WmTag[] = [];
  public selected: WmTag | null = null;

  public loadGroup() {
    if (this.categories.length && this.categories[0].group) {
      const selected =
        this.selectedCategories.find(
          sc =>
            (sc.group && this.categories[0].group!.name === sc.group!.name) ||
            this.categories[0].group!.name === sc.group_name
        ) || null;
      this.selected =
        (selected && this.categories.find(c => c.id === selected.id)) || null;
    }
    return (
      (this.categories.length &&
        this.categories[0].group &&
        this.$t(this.categories[0].group!.name)) ||
      ""
    );
  }

  public listenerGroup(categories: WmTag[]) {
    return sortBy(toArray(groupBy(categories, "group.name")), c => {
      return !!c.find(sc => !!sc.children);
    });
  }

  public updateCategories(newCategories: WmTag[]) {
    this.newCategories = (newCategories.length && newCategories) || [];
    if (this.selected) {
      const brothers = this.selectedCategories.filter(sc => {
        return (
          sc.parent_id === this.selected!.parent_id &&
          ((sc.group && this.selected!.group!.id !== sc.group!.id) ||
            (sc.weedmaps_tag_group_id &&
              this.selected!.group!.weedmaps_tag_group_id !==
                sc.weedmaps_tag_group_id))
        );
      });
      if (brothers.length) {
        const childOfBrother = this.selectedCategories.filter(sc => {
          return !!brothers.find(b => sc.parent_id === b.weedmaps_tag_id);
        });
        this.newCategories = [
          ...this.newCategories,
          ...brothers,
          ...childOfBrother
        ];
      }
      this.newCategories.push(this.selected);
      this.$emit("updateCategories", this.newCategories);
    }
  }
}
