import BatchesProductComponent from "@/components/inventory/ProductForm/BatchesProduct/BatchesProduct.component";
import DataSharingComponent from "@/components/inventory/ProductForm/DataSharing/DataSharing.component";
import PackagingDetailComponent from "@/components/inventory/ProductForm/PackagingDetail/PackagingDetail.component";
import PictureProductComponent from "@/components/inventory/ProductForm/PictureProduct/PictureProduct.component";
import PrescriptionDetailsComponent from "@/components/inventory/ProductForm/PrescriptionDetails/PrescriptionDetails.component";
import PricingDiscountComponent from "@/components/inventory/ProductForm/PricingDiscount/PricingDiscount.component";
import SharedProductBasicInfoComponent from "@/components/systemSettings/sharedProducts/sharedProductForm/sharedProductBasicInfo/SharedProductBasicInfo.component";
import { policyList } from "@/enums/permissions";
import { Brand } from "@/interfaces/brand";
import { Category } from "@/interfaces/category";
import { Location } from "@/interfaces/location";
import { Modal } from "@/interfaces/modal";
import { PusherNotification } from "@/interfaces/notification";
import { defaultPrescriptionDetails } from "@/interfaces/prescriptionDetails";
import {
  PriceGroupRules,
  PriceSetting,
  PricingRule,
  Product,
  ProductBasicInfo,
  productDefault
} from "@/interfaces/product";
import { RetailSettings } from "@/interfaces/retailSettings";
import {
  SharedProductLocation,
  SharedProductTool
} from "@/interfaces/sharedProductLocations";
import {
  SharingData,
  SharingDataModel,
  SharingProductType
} from "@/interfaces/sharing";
import { Strain } from "@/interfaces/strain";
import { ProductMetrcItem } from "@/interfaces/traceability";
import { User } from "@/interfaces/user";
import { Vendor } from "@/interfaces/vendor";
import { WmIntegratedProduct } from "@/interfaces/WeedmapsData";
import { EventBus, pusherEvents } from "@/internal";
import { ChangesNotificationService } from "@/plugins/changes-notifications/changes-notification.service";
import { metrcEnabled, weedMapsEnabled } from "@/router.utils";
import { locationService } from "@/services/location.service";
import { messagesService } from "@/services/messages.service";
import { permissionService } from "@/services/permission.service";
import { productService } from "@/services/product.service";
import { sharedProductService } from "@/services/sharedProduct.service";
import { sharingService } from "@/services/sharing.service";
import { weedmapsInfoService } from "@/services/weedmapsInfo.service";
import { Callback, PageNavAction } from "@/types/types";
import { BooleanCheck } from "@/types/types";
import cloneDeep from "lodash/cloneDeep";
import { Validator } from "vee-validate";
import { Component, Inject, Provide, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import SharedProductCategorieComponent from "./SharedProductCategorie.component";
import Template from "./SharedProductForm.template.vue";
import SharedProductPriceComponent from "./sharedProductPrice/SharedProductPrice.component";

const namespace: string = "ProductModule";

enum ProductTabs {
  productCategory = "product-Category",
  basicInfo = "basic-info",
  batchTypes = "batch-Types",
  strains = "strains",
  pictureProduct = "picture-product",
  brands = "brands",
  productMetrcInfo = "metrc-info",
  packageDetails = "Packaging",
  vendorsLaboratories = "vendors-Laboratories",
  pricing = "pricing"
}

@Component({
  mixins: [Template],
  components: {
    SharedProductBasicInfoComponent,
    DataSharingComponent,
    PricingDiscountComponent,
    PackagingDetailComponent,
    PictureProductComponent,
    BatchesProductComponent,
    PrescriptionDetailsComponent,
    SharedProductCategorieComponent,
    SharedProductPriceComponent
  },
  inject: ["$changes"]
})
export default class ProductFormComponent extends Vue {
  public disabled = true;
  @Inject("$validator") public $validator!: Validator;

  @Provide()
  public validator = this.$validator;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  public activeTab: string | null = null;
  public ruleArray: PriceGroupRules[] = [];
  public rulePostArray: PriceGroupRules[] = [];
  public isSaving = false;
  public isLoading = false;
  public sharedProductTabs = ProductTabs;
  public errorGetBrandWM = false;
  public WmIntegrationData: WmIntegratedProduct | null = null;
  public hasWmIntegration = weedMapsEnabled();
  public productMetrcInfo: ProductMetrcItem = {
    biotrack_sku: null,
    metrc_id: null,
    isNewItem: true
  };
  public hasMetrc = metrcEnabled();
  public isPharmacyEnabled = false;
  public isModifiableProducts: boolean = true;
  public sharingData: SharingData | null = null;
  public switchesModel: SharingDataModel = {
    product: false,
    product_separate_product_category: false,
    product_separate_batch_types: false,
    product_separate_price_settings: false,
    product_separate_price_group: false,
    product_separate_brand: false,
    product_separate_strain: false,
    product_separate_vendor_laboratories: false
  };
  public priceSetting: SharedProductLocation[] = [];
  public isPriceUpdated = false;
  public isPricingOptionsUpdated = false;

  public sharedProductData: SharingProductType = {
    product_categories: [],
    batch_types: [],
    price_settings: [],
    strains: [],
    weighing_info: [],
    brands: [],
    vendor: []
  };

  public sharedProductLocation: SharingProductType = {
    product_categories_location: [],
    batch_types_location: [],
    price_settings_location: [],
    strains_location: [],
    weighing_info_location: [],
    brands_location: [],
    vendor_location: []
  };

  public productBasicInfoData: ProductBasicInfo = {
    name: "",
    strain_id: "",
    batch_type_id: "",
    product_category_id: "",
    brand_id: 0,
    vendor_id: 0,
    barcode: "",
    ndc_number: "",
    description: "",
    marijuana: 1,
    requires_weighing: 0,
    require_inventory: 0,
    metrc_weighable: false,
    usable_weight_value: 0,
    usable_weight_unit: "g",
    weight_per_unit_value: 0,
    weight_per_unit_unit: "g"
  };

  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public currentRetailSettings!: RetailSettings;

  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;

  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;

  @Getter("strains", { namespace })
  protected strainItems!: Strain[];
  @Getter("batchTypes", { namespace })
  protected batchTypeItems!: Strain[];
  @Getter("categories", { namespace })
  protected categoryItems!: Category[];
  @Getter("brands", { namespace })
  protected brandItems!: Brand[];
  @Getter("vendors", { namespace })
  protected vendorItems!: Vendor[];
  protected modalData: Modal = { title: null, width: "603px", height: "200px" };

  @Action("errorRedirect", { namespace: "RouterModule" })
  protected errorRedirect!: Callback;

  protected product: Product = { ...productDefault };
  protected changes$!: ChangesNotificationService;

  @Getter("user", { namespace: "AuthModule" })
  protected user!: User;

  public async mounted() {
    this.sharingData = await sharingService.getSharing();
    this.sharingData!.entities.PRODUCT.separated.map(item => {
      if (item === "product_category") {
        this.switchesModel.product_separate_product_category = true;
      }
      if (item === "batch_types") {
        this.switchesModel.product_separate_batch_types = true;
      }
      if (item === "price_settings") {
        this.switchesModel.product_separate_price_settings = true;
      }
      if (item === "price_group") {
        this.switchesModel.product_separate_price_group = true;
      }
      if (item === "brand") {
        this.switchesModel.product_separate_brand = true;
      }
      if (item === "strain") {
        this.switchesModel.product_separate_strain = true;
      }
      if (item === "vendor_laboratories") {
        this.switchesModel.product_separate_vendor_laboratories = true;
      }
    });
    this.isModifiableProducts = this.hasPermission(
      policyList.modifySharedProducts
    );
    this.isPharmacyEnabled = !!this.currentRetailSettings.pharmacy_mode_enabled;
    this.setPageNav({
      title: "shared products",
      isLoading: () => this.isLoading || this.isSaving,
      rightActions: {
        generalActions: () => [
          {
            icon: "fal fa-check",
            id: "btn_save",
            action: () => this.save(),
            visibleCondition: () => this.isModifiableProducts,
            vuetifyProps: () => ({
              loading: this.isSaving,
              fab: true,
              small: true
            })
          },
          {
            icon: "fal fa-times",
            id: "btn_cancel",
            action: this.cancel,
            vuetifyProps: () => ({
              loading: this.isSaving,
              fab: true,
              small: true
            })
          }
        ]
      }
    });
  }

  public async save() {
    try {
      this.isSaving = true;
      this.seperateToggleEnabled();
      this.productBasicInfo();
      await sharedProductService.onSaveSharedProduct(
        this.sharedProductData,
        this.product.sku,
        this.productBasicInfoData
      );

      if (this.isPriceUpdated) {
        if (this.rulePostArray.length > 0) {
          // @ts-ignore
          const rulePostArrayPayload = this.rulePostArray as PricingRule[];
          await sharedProductService.setProductPricing(
            rulePostArrayPayload,
            "post"
          );
        }
        if (this.ruleArray.length > 0) {
          // @ts-ignore
          const ruleArrayPayload = this.ruleArray as PricingRule[];
          await sharedProductService.setProductPricing(ruleArrayPayload, "put");
        }
      }

      this.$router.back();
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      this.isSaving = false;
    }
  }

  public productBasicInfo() {
    this.productBasicInfoData = {
      name: this.product.name,
      barcode: this.product.barcode,
      ndc_number: this.product.ndc_number,
      description: this.product.description,
      marijuana: this.product.marijuana,
      requires_weighing: this.product.requires_weighing,
      require_inventory: this.product.require_inventory,
      metrc_weighable: Boolean(this.product.metrc_weighable),
      usable_weight_value: Number(this.product.usable_weight_value),
      usable_weight_unit: this.product.usable_weight_unit,
      weight_per_unit_value: Number(this.product.weight_per_unit_value),
      weight_per_unit_unit: this.product.weight_per_unit_unit
    };

    if (!this.switchesModel.product_separate_strain) {
      if (this.product.strain_id) {
        const strainIndex = this.strainItems.findIndex(
          strain => String(strain.id) === String(this.product.strain_id)
        );
        const strainData = {
          location_id: Number(this.currentLocation.id),
          productName: this.strainItems[strainIndex],
          uid: String(this.product.strain_id)
        };
        // @ts-ignore
        this.sharedProductData.strains = [strainData];
      }
    }
    if (!this.switchesModel.product_separate_batch_types) {
      if (this.product.batch_type_id) {
        const batchTypeIndex = this.batchTypeItems.findIndex(
          batchType =>
            String(batchType.id) === String(this.product.batch_type_id)
        );
        const batchTypeData = {
          location_id: Number(this.currentLocation.id),
          productName: this.batchTypeItems[batchTypeIndex],
          uid: String(this.product.batch_type_id)
        };
        // @ts-ignore
        this.sharedProductData.batch_types = [batchTypeData];
      }
    }

    if (!this.switchesModel.product_separate_product_category) {
      if (this.product.product_category_id) {
        const categoryIndex = this.categoryItems.findIndex(
          category =>
            String(category.id) === String(this.product.product_category_id)
        );
        const categoryData = {
          location_id: Number(this.currentLocation.id),
          productName: this.categoryItems[categoryIndex],
          uid: String(this.product.product_category_id)
        };
        // @ts-ignore
        this.sharedProductData.product_categories = [categoryData];
      }
    }
    if (!this.switchesModel.product_separate_brand) {
      if (this.product.brand_id) {
        const brandIndex = this.brandItems.findIndex(
          brand => String(brand.id) === String(this.product.brand_id)
        );
        if (this.brandItems[brandIndex].location_id !== undefined) {
          delete this.brandItems[brandIndex].location_id;
        }
        const brandData = {
          location_id: Number(this.currentLocation.id),
          productName: this.brandItems[brandIndex],
          uid: String(this.product.brand_id)
        };
        // @ts-ignore
        this.sharedProductData.brands = [brandData];
      }
    }
    if (!this.switchesModel.product_separate_vendor_laboratories) {
      if (this.product.vendor_id) {
        const vendorIndex = this.vendorItems.findIndex(
          vendor => String(vendor.id) === String(this.product.vendor_id)
        );
        // tslint:disable-next-line
        const selectedVendorData = {
          name: this.vendorItems[vendorIndex].name,
          documents: this.vendorItems[vendorIndex]!.documents || null,
          notes: this.vendorItems[vendorIndex]!.notes || null,
          contacts: this.vendorItems[vendorIndex]!.contacts || null,
          id: String(this.product.vendor_id)
        };
        const vendorData = {
          location_id: Number(this.currentLocation.id),
          productName: selectedVendorData,
          uid: String(this.product.vendor_id)
        };
        // @ts-ignore
        this.sharedProductData.vendor = [vendorData];
      }
    }
  }

  public seperateToggleEnabled() {
    this.sharedProductData.product_categories.map((item: SharedProductTool) => {
      const selctedProduct = item.product_data.find(
        (element: SharedProductTool) => element.id === item.selectedUid
      );
      item.location_id = item.locationId;
      item.productName = selctedProduct;
      item.uid! = item!.selectedUid!;
    });
    this.sharedProductData.brands.map((item: SharedProductTool) => {
      const selctedProduct = item.product_data.find(
        (element: SharedProductTool) => element.id === item.selectedUid
      );
      item.location_id = item.locationId;
      item.productName = selctedProduct;
      item.uid! = item!.selectedUid!;
    });
    this.sharedProductData.strains.map((item: SharedProductTool) => {
      const selctedProduct = item.product_data.find(
        (element: SharedProductTool) => element.id === item.selectedUid
      );
      item.location_id = item.locationId;
      item.productName = selctedProduct;
      item.uid! = item!.selectedUid!;
    });
    this.sharedProductData.batch_types.map((item: SharedProductTool) => {
      const selctedProduct = item.product_data.find(
        (element: SharedProductTool) => element.id === item.selectedUid
      );
      item.location_id = item.locationId;
      item.productName = selctedProduct;
      item.uid! = item!.selectedUid!;
    });
    this.sharedProductData.vendor.map((item: SharedProductTool) => {
      const selctedProduct = item.product_data.find(
        (element: SharedProductTool) => element.id === item.selectedUid
      );
      item.location_id = item.locationId;
      item.productName = selctedProduct;
      item.uid! = item!.selectedUid!;
    });
    if (!this.isPriceUpdated && !this.isPricingOptionsUpdated) {
      delete this.sharedProductData.price_settings;
    }
  }

  public cancel() {
    this.$router.back();
  }

  public setDisabled(value: boolean) {
    this.disabled = value;
  }

  public updatePricing() {
    this.isPriceUpdated = true;
    this.isPricingOptionsUpdated = true;
  }
  public updatingPricingOptions() {
    this.isPricingOptionsUpdated = true;
  }
  public ruleModel(data: PriceGroupRules) {
    if (data.rules.length) {
      if (this.ruleArray.length === 0) {
        this.ruleArray.push(data);
      } else {
        const filteredData = this.ruleArray.filter(items => {
          if (items.location_id === data.location_id) {
            items = data;
            return true;
          } else {
            return false;
          }
        });
        if (filteredData.length >= 1) {
          for (let i = 0; i < this.ruleArray.length; i++) {
            if (this.ruleArray[i].location_id === data.location_id) {
              this.ruleArray[i] = data;
            }
          }
        } else {
          this.ruleArray.push(data);
        }
      }
    }
  }

  public rulePostModel(data: PriceGroupRules) {
    if (data.rules.length) {
      if (this.rulePostArray.length === 0) {
        this.rulePostArray.push(data);
      } else {
        const filteredData = this.rulePostArray.filter(items => {
          if (items.location_id === data.location_id) {
            items = data;
            return true;
          } else {
            return false;
          }
        });
        if (filteredData.length >= 1) {
          for (let i = 0; i < this.rulePostArray.length; i++) {
            if (this.rulePostArray[i].location_id === data.location_id) {
              this.rulePostArray[i] = data;
            }
          }
        } else {
          this.rulePostArray.push(data);
        }
      }
    }
  }

  public updatedLocationPrice(data: SharedProductTool) {
    let bool = false;
    this.sharedProductData.price_settings.map(item => {
      if (item.location_id === data.location_id) {
        bool = true;
        return data;
      }
      return item;
    });
    if (bool === false) {
      this.sharedProductData.price_settings = [
        ...this.sharedProductData.price_settings,
        data
      ];
    }
  }

  public updateSharedValue(data: SharedProductTool[], typeName: string) {
    data.map((item: SharedProductTool) => {
      if (typeof item.selectedUid === "string" && item.selectedUid !== "") {
        return null;
      } else {
        Object.entries(item.selectedUid).forEach(key => {
          if (key[0] === "id") {
            item.selectedUid = key[1];
          }
        });
      }
    });
    if (data.length) {
      data.map((item: SharedProductTool) => {
        if (item.type === "category") {
          this.sharedProductData.product_categories = data;
        } else if (item.type === "brand") {
          this.sharedProductData.brands = data;
        } else if (item.type === "strain") {
          this.sharedProductData.strains = data;
        } else if (item.type === "batch_type") {
          this.sharedProductData.batch_types = data;
        } else if (item.type === "vendor") {
          this.sharedProductData.vendor = data;
        }
      });
    } else {
      if (typeName === "category") {
        this.sharedProductData.product_categories = data;
      } else if (typeName === "brand") {
        this.sharedProductData.brands = data;
      } else if (typeName === "strain") {
        this.sharedProductData.strains = data;
      } else if (typeName === "batch_type") {
        this.sharedProductData.batch_type = data;
      } else if (typeName === "vendor") {
        this.sharedProductData.vendor = data;
      }
    }
  }

  protected async loadProduct() {
    try {
      const productResponse = await productService.findBySkuId(
        this.$route.params.id,
        {
          load_has_batches: true
        }
      );
      if (!productResponse.prescription_detail) {
        productResponse.prescription_detail = defaultPrescriptionDetails;
      }
      if (productResponse.prescription_detail!.quantity) {
        productResponse.prescription_detail.quantity = +productResponse.prescription_detail!
          .quantity!;
      }
      if (productResponse.prescription_detail.days_supply!) {
        productResponse.prescription_detail!.days_supply = +productResponse.prescription_detail!
          .days_supply!;
      }
      if (productResponse!.nutrition_calories_per_serving!) {
        productResponse!.nutrition_calories_per_serving = +productResponse!
          .nutrition_calories_per_serving!;
      }
      if (productResponse!.nutrition_total_calories) {
        productResponse!.nutrition_total_calories = +productResponse!
          .nutrition_total_calories!;
      }
      this.product = cloneDeep(productResponse);

      const locationDetails = await permissionService.getUserLocations(
        this.user.id
      );

      const locationIds = locationDetails.map(item => item.id);

      this.sharedProductLocation.product_categories_location = await sharedProductService.getAll(
        this.product.sku,
        "category",
        locationIds
      );
      this.sharedProductLocation.batch_types_location = await sharedProductService.getAll(
        this.product.sku,
        "batch_type",
        locationIds
      );
      this.sharedProductLocation.strains_location = await sharedProductService.getAll(
        this.product.sku,
        "strain",
        locationIds
      );
      this.sharedProductLocation.brands_location = await sharedProductService.getAll(
        this.product.sku,
        "brand",
        locationIds
      );
      this.sharedProductLocation.vendor_location = await sharedProductService.getAll(
        this.product.sku,
        "vendor",
        locationIds
      );

      this.sharedProductLocation.price_settings_location = await sharedProductService.getAll(
        this.product.sku,
        "price_settings",
        locationIds
      );
    } catch (e) {
      return e;
    }
  }

  protected async created() {
    if (this.$route.params.id) {
      this.isLoading = true;

      const [error, WmIntegrationData] = await Promise.all([
        this.loadProduct(),
        this.hasWmIntegration
          ? weedmapsInfoService.getProduct(this.$route.params.id)
          : null
      ]);

      this.productMetrcInfo.biotrack_sku = this.$route.params.id;

      this.isLoading = false;
      if (error) {
        this.setDisabled(false);
        this.errorRedirect({ error });
      }

      EventBus.$on("detailsBatches.products", () => {
        this.activeTab = "batch-assigned";
      });
      EventBus.$on("errorBrandWeedMaps", (value: boolean) => {
        this.errorGetBrandWM = value;
      });
      this.$changes.watch(
        pusherEvents.productTouched,
        async () => {
          this.isLoading = true;
          await this.loadProduct();
          this.product = { ...productDefault };
          this.isLoading = false;
        },
        (data: PusherNotification) => {
          if (this.product && this.product.id) {
            return !!data.message.find(n => n.item_id === this.product.id);
          }

          return false;
        }
      );
    }
  }
}
