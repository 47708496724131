import { priceSettingsDefault, Product } from "@/interfaces/product";
import {
  SharedProductLocation,
  SharedProductTool
} from "@/interfaces/sharedProductLocations";
import { sharedProductService } from "@/services/sharedProduct.service";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Template from "./SharedProductCategorie.template.vue";

@Component({
  mixins: [Template]
})
export default class SharedProductCategorieComponent extends Vue {
  @Prop({ required: true, default: [] })
  public locations!: SharedProductLocation[];

  @Prop({ required: true })
  public tabName!: string;

  @Prop({ required: true })
  public active!: boolean;

  @Prop({ default: false })
  public isModifiableProducts!: boolean;

  @Prop({ required: true })
  public product!: Product;

  public selected: any[] = [];
  public isSelectAll: boolean = false;

  public onActionChange() {
    this.$emit(
      "updateSharedValue",
      this.selected.filter(item => item.selectedUid),
      this.tabName
    );
  }

  public async onSelectAll() {
    this.isSelectAll = !this.isSelectAll;
    if (!this.selected.length) {
      if (this.isSelectAll) {
        const tempId = this.locations.map(item => item.locationId);
        const tempLocation = this.locations.map(item => item.locationName);
        const selectCall = await sharedProductService.put(
          this.product.sku,
          this.tabName,
          tempId
        );
        selectCall.map((allItems: SharedProductTool, index: number) => {
          allItems.locationName = tempLocation[index];
          allItems.type = this.tabName;
          if (selectCall[index].product_data === null) {
            selectCall[index].product_data = [];
          }

          if (selectCall[index].products_details === null) {
            selectCall[index].products_details = {
              [this.tabName]: null,
              sku: "",
              price_settings: priceSettingsDefault
            };
          } else {
            selectCall[index].selectedUid =
              (selectCall[index].products_details &&
                selectCall[index].products_details[this.tabName]) ||
              null;
          }
        });
        this.selected = selectCall;
      } else {
        this.selected = [];
        this.onActionChange();
      }
    } else {
      if (this.isSelectAll) {
        const remainingId = this.selected.map(item => item.locationId);
        const tempId = this.locations.map(item => item.locationId);

        const selectRemainingAll = tempId.filter(
          val => !remainingId.includes(val)
        );

        const remainingLocation = this.selected.map(item => item.locationName);

        const tempLocation = this.locations.map(item => item.locationName);

        const selectRemainingName = tempLocation.filter(
          val => !remainingLocation.includes(val)
        );

        const selectCall = await sharedProductService.put(
          this.product.sku,
          this.tabName,
          selectRemainingAll
        );

        selectCall.map((allItems: SharedProductTool, index: number) => {
          allItems.locationName = selectRemainingName[index];
          allItems.type = this.tabName;
          if (selectCall[index].product_data === null) {
            selectCall[index].product_data = [];
          }

          if (selectCall[index].products_details === null) {
            selectCall[index].products_details = {
              [this.tabName]: null,
              sku: "",
              price_settings: priceSettingsDefault
            };
          } else {
            selectCall[index].selectedUid =
              (selectCall[index].products_details &&
                selectCall[index].products_details[this.tabName]) ||
              null;
          }
        });
        this.locations.map((item, index) => {
          const selectedIndex = selectCall.findIndex(
            (subItem: SharedProductTool) =>
              subItem.locationId === item.locationId
          );
          if (selectedIndex !== -1) {
            this.selected.splice(index, 1, selectCall[selectedIndex]);
          }
        });
      } else {
        this.selected = [];
        this.onActionChange();
      }
    }
  }
  public async onSelected(
    location: SharedProductLocation,
    event: boolean,
    index: number
  ) {
    const tabName = this.tabName;
    const tempId = [];
    if (event) {
      tempId.push(location.locationId);
      const selectCall = await sharedProductService.put(
        this.product.sku,
        this.tabName,
        tempId
      );
      selectCall.map((item: SharedProductTool) => {
        item.locationName = location.locationName;
        item.type = this.tabName;
      });
      if (selectCall[0].product_data === null) {
        selectCall[0].product_data = [];
      }

      if (selectCall[0].products_details === null) {
        selectCall[0].products_details = {
          [tabName]: null,
          sku: ""
        };
      } else {
        selectCall[0].selectedUid =
          (selectCall[0].products_details &&
            selectCall[0].products_details[tabName]) ||
          null;
      }
      if (!this.selected.length) {
        this.selected = new Array(this.locations.length).fill("");
        this.selected.splice(index, 1, selectCall[0]);
      } else {
        this.selected.splice(index, 1, selectCall[0]);
      }
    } else {
      this.selected.splice(index, 1, "");
      this.onActionChange();
    }
  }
}
