import { Location } from "@/interfaces/location";
import { MemberLevel } from "@/interfaces/memberLevel";
import { PriceGroupInterface } from "@/interfaces/priceGroups";
import {
  PriceGroup,
  PriceGroupRules,
  PriceSetting,
  priceSettingsDefault,
  PricingRule,
  Product,
  ProductCost,
  ProductPricing
} from "@/interfaces/product";
import {
  SharedProductLocation,
  SharedProductTool
} from "@/interfaces/sharedProductLocations";
import { TaxCategory } from "@/interfaces/taxCategory";
import { priceGroupService } from "@/services/priceGroup.service";
import { productService } from "@/services/product.service";
import { sharedProductService } from "@/services/sharedProduct.service";
import { BooleanCheck, Callback } from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import { Validator } from "vee-validate";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Inject } from "vue-property-decorator";
import { Getter, Mutation } from "vuex-class";
import SharedPricePointStructureComponent from "./sharedPricePoint/SharedPricePoint.component";
import Template from "./SharedProductPrice.template.vue";

@Component({
  mixins: [Template],
  components: {
    SharedPricePointStructureComponent
  }
})
export default class PricingDiscountComponent extends Vue {
  @Prop({ required: true })
  public locations!: SharedProductLocation[];

  @Prop({ required: true })
  public tabName!: string;

  @Prop({ required: true })
  public seperate!: boolean;

  @Prop({ required: true })
  public seperatePriceGroup!: boolean;

  public newLocations: Location[] = [];

  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;

  @Prop({ required: true })
  public active!: boolean;
  /**
   * model to save
   * @var Product product
   */
  @Prop({ required: true })
  public product!: Product;

  @Prop({ default: false })
  public isModifiableProducts!: boolean;

  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;

  @Inject("$validator") public $validator!: Validator;

  public header: ProductCost = {
    current_total_cost: 0,
    average_cost_per_unit: 0,
    total_potential_profit: 0
  };
  public renderPrices = false;
  public pricingModel: PricingRule[] = [];
  public ruleModel: PricingRule[] = [];
  public modelData: PricingRule[] = [];
  public hasPrices = false;
  public renderReady = false;
  public priceGroupItems: PriceGroupInterface[] = [];
  public loadingPriceGroup = false;
  public productPricing!: ProductPricing | null;
  public showPriceGroupDropdown = false;
  public locationResponse: PriceSetting = priceSettingsDefault;
  public locationPayload: { [key: string]: SharedProductTool } = {};
  public selected: null | number = null;
  public memberId!: number;
  public memberItems: MemberLevel[] = [];
  public priceTaxCategories: TaxCategory[] = [];
  public ruleSet!: PriceGroup;
  public locationSku!: string;
  public isRuleSet!: boolean;
  public taxCategoryId!: TaxCategory | null;
  public ruleModelArray: PriceGroupRules[] = [];
  public idSelect: number[] = [];
  public locationData: PriceSetting[] = [];
  public isUsableWeight: boolean = true;
  public priceGroupAssigned: PriceGroupInterface | null = null;
  public productSelected: boolean = true;
  protected pricePoint: string = "RECREATIONAL";
  protected selectedId: number[] = [];
  protected pricingStyle: string | undefined | null = null;

  public idSelection(id: number) {
    let checkData;
    if (this.idSelect.length === 0) {
      this.idSelect.push(id);
      this.onSelected(id);
    } else {
      for (const item of this.idSelect) {
        if (item === id) {
          checkData = true;
        }
      }
      if (checkData) {
        for (const items of this.ruleModelArray) {
          if (items.location_id === id) {
            this.ruleModel = items.rules;
          }
        }
        for (const data of this.locationData) {
          if (data.location_id === id) {
            this.locationResponse = cloneDeep(data);
          }
        }
        this.onSelected(id, true);
      } else {
        this.idSelect.push(id);
        this.onSelected(id);
      }
    }
  }

  public async onSelected(id: number, selected?: boolean) {
    this.selected = id;
    const noneMemberLevel: MemberLevel = {
      id: null,
      code: null,
      name: this.$t("inventory.none").toString()
    };

    const selectedLocationPayload = cloneDeep(this.locationPayload[id]);
    this.locationSku = selectedLocationPayload.products_details!.sku;

    if (selectedLocationPayload && selectedLocationPayload.product_data!) {
      if (selectedLocationPayload.product_data.pricing_group.length) {
        this.priceGroupItems =
          selectedLocationPayload.product_data.pricing_group || [];
        if (this.locationResponse.pricing_group_id) {
          setTimeout(() => {
            const priceGroupIdIndex = this.priceGroupItems.findIndex(
              item =>
                item.pricing_group_id === this.locationResponse.pricing_group_id
            );
            if (priceGroupIdIndex !== -1) {
              this.locationResponse.pricing_type = "PRICE_GROUP";
              const selectedItemIndex = this.priceGroupItems.findIndex(
                item => item.id === this.locationResponse.pricing_group_id
              );
              const priceGroupStyle = this.priceGroupItems[selectedItemIndex];
              if (
                priceGroupStyle &&
                (priceGroupStyle.pricing_style ||
                  priceGroupStyle.pricing_style !== null ||
                  priceGroupStyle.pricing_style !== undefined)
              ) {
                this.autoSelectUsableWeight(priceGroupStyle.pricing_style);
              }
              this.locationResponse.pricing_group_id = this.priceGroupItems[
                priceGroupIdIndex
              ].id;
            } else {
              const priceGroupNameIndex = this.priceGroupItems.findIndex(
                item =>
                  String(item.name) ===
                  String(this.locationResponse.price_group)
              );
              if (priceGroupNameIndex !== -1) {
                this.locationResponse.pricing_type = "PRICE_GROUP";
                const selectedItemIndex = this.priceGroupItems.findIndex(
                  item => item.id === this.locationResponse.pricing_group_id
                );
                const priceGroupStyle = this.priceGroupItems[selectedItemIndex];
                if (
                  priceGroupStyle &&
                  (priceGroupStyle.pricing_style ||
                    priceGroupStyle.pricing_style !== null ||
                    priceGroupStyle.pricing_style !== undefined)
                ) {
                  this.autoSelectUsableWeight(priceGroupStyle.pricing_style);
                }
                this.locationResponse.pricing_group_id = this.priceGroupItems[
                  priceGroupNameIndex
                ].id;
              }
            }
          }, 10);
        }
      }
      this.memberItems = [noneMemberLevel];
      selectedLocationPayload.product_data.member_levels.map(
        (items: { member_level: MemberLevel }) => {
          this.memberItems.push(items.member_level);
        }
      );
      if (selectedLocationPayload.product_data.tax_category) {
        this.priceTaxCategories = this.locationPayload[
          id
        ].product_data.tax_category;
      }
    } else {
      this.memberItems = [noneMemberLevel];
    }
    if (
      selectedLocationPayload &&
      selectedLocationPayload.rule_set!.rules.length
    ) {
      this.isRuleSet = true;
    } else {
      this.isRuleSet = false;
    }
    if (!selected) {
      if (
        selectedLocationPayload &&
        selectedLocationPayload.rule_set!.rules.length
      ) {
        this.ruleModel = selectedLocationPayload.rule_set!.rules;
        this.ruleSet = selectedLocationPayload.rule_set!;
        this.isRuleSet = true;
        this.locationResponse.pricing_type = "CUSTOM";
      } else {
        this.isRuleSet = false;
        this.ruleModel = [];
        this.taxCategoryId = null;
      }

      if (selectedLocationPayload) {
        if (selectedLocationPayload.products_details) {
          this.locationSku = selectedLocationPayload.products_details!.sku;
          this.locationResponse = cloneDeep(
            selectedLocationPayload!.products_details!.price_settings
          );
          this.locationResponse.location_id = id;
        } else {
          this.locationSku = this.product.sku;
          this.locationResponse = cloneDeep(priceSettingsDefault);
          this.locationResponse.location_id = id;
        }
      }
    }
  }

  @Watch("locationResponse", { deep: true })
  public locationChanged() {
    if (this.locationData.length === 0) {
      this.locationData.push(this.locationResponse);
    } else {
      const filteredData = this.locationData.filter(
        items => items.location_id === this.locationResponse.location_id
      );

      if (filteredData.length >= 1) {
        for (let i = 0; i < this.locationData.length; i++) {
          if (
            this.locationData[i].location_id ===
            this.locationResponse.location_id
          ) {
            this.locationData[i] = this.locationResponse;
          }
        }
      } else {
        this.locationData.push(this.locationResponse);
      }
    }

    this.$emit("updatedLocationPrice", this.locationResponse);
  }

  @Watch("ruleModel", { deep: true })
  public ruleModelChanged() {
    const newData = {
      is_active: true,
      code: this.locationSku,
      location_id: this.selected,
      rules: this.ruleModel
    };
    if (this.ruleModelArray.length === 0) {
      this.ruleModelArray.push(newData);
    } else {
      const filteredData = this.ruleModelArray.filter(items => {
        if (items.location_id === newData.location_id) {
          items = newData;
          return true;
        } else {
          return false;
        }
      });
      if (filteredData.length >= 1) {
        for (let i = 0; i < this.ruleModelArray.length; i++) {
          if (this.ruleModelArray[i].location_id === newData.location_id) {
            this.ruleModelArray[i] = newData;
          }
        }
      } else {
        this.ruleModelArray.push(newData);
      }
    }
    if (this.isRuleSet) {
      this.$emit("ruleModel", newData);
    } else {
      this.$emit("rulePostModel", newData);
    }
  }

  public validateNumber(event: KeyboardEvent) {
    if (event.key !== ".") {
      if (isNaN(+event.key)) {
        event.preventDefault();
      }
    }
    return true;
  }

  public changingPricingOptions() {
    this.$emit("updatingPricingOptions");
  }

  public emitPricing(changed = true) {
    if (changed) {
      this.$emit("updatePricing");
    }
  }

  public autoSelectUsableWeight(pricingStyle?: string) {
    if (!this.product.requires_weighing && this.product.marijuana) {
      if (pricingStyle === "units") {
        this.locationResponse.price_point_by_usable_weight = 0;
      } else if (pricingStyle === "weight") {
        this.locationResponse.price_point_by_usable_weight = 1;
      }
      this.isUsableWeight = false;
    }
    this.pricingStyle = pricingStyle;
  }

  public async assignSelectedPriceGroupRules() {
    if (
      this.locationResponse.pricing_type === "PRICE_GROUP" &&
      this.locationResponse.pricing_group_id
    ) {
      const selectedItemIndex = this.priceGroupItems.findIndex(
        item => item.id === this.locationResponse.pricing_group_id
      );
      const priceGroupStyle = this.priceGroupItems[selectedItemIndex];
      if (
        priceGroupStyle &&
        (priceGroupStyle.pricing_style ||
          priceGroupStyle.pricing_style !== null ||
          priceGroupStyle.pricing_style !== undefined)
      ) {
        this.autoSelectUsableWeight(priceGroupStyle.pricing_style);
      }

      const priceGroup = await priceGroupService.findById(
        String(this.locationResponse.pricing_group_id)
      );
      this.priceGroupAssigned = priceGroup;
      this.locationResponse.price_group = priceGroup.name;
      this.ruleModel = [];
      if (priceGroup && priceGroup.rules && priceGroup.rules.length) {
        priceGroup.rules.map((item: PricingRule) => {
          if (item.id) {
            delete item.id;
          }
        });
        this.ruleModel = priceGroup.rules;
      } else {
        const productPricingModel =
          cloneDeep(this.productPricing && this.productPricing.rules) || [];
        productPricingModel.map(items => {
          items.price_breaks = [];
        });
        this.ruleModel = productPricingModel;
      }
      this.hasPrices =
        this.productPricing &&
        this.productPricing.rules &&
        this.productPricing.rules.length
          ? true
          : false;

      this.emitPricing(true);
    } else if (this.locationResponse.pricing_type === "CUSTOM") {
      this.ruleModel = [];
      this.locationResponse.pricing_group_id = null;
      this.locationResponse.price_group = null;
      if (this.$route.name === "products-add") {
        this.setProductPricingModel();
      }
    }
    this.showPriceGroupDropdown =
      this.locationResponse.pricing_type === "PRICE_GROUP" ? true : false;
    this.changingPricingOptions();
  }

  public async setProductPricingModel() {
    if (!this.productPricing) {
      this.productPricing = this.product.sku
        ? await productService.getProductPricing(this.product.sku)
        : null;
    }

    this.ruleModel =
      cloneDeep(this.productPricing && this.productPricing.rules) || [];
    this.emitPricing(false);
    this.renderPrices = true;
  }

  protected async mounted() {
    const locationIds = this.locations.map(item => item.locationId);
    const locations = await sharedProductService.put(
      this.product.sku,
      this.tabName,
      locationIds
    );
    locations.map((item: SharedProductTool) => {
      if (item.products_details) {
        item.products_details = item.products_details;

        if (item.products_details.price_settings.location_id) {
          delete item.products_details.price_settings.location_id;
        }
        this.locationResponse = cloneDeep(item.products_details.price_settings);
      } else {
        item.products_details = {
          sku: "",
          price_settings: priceSettingsDefault
        };
      }
      if (item.rule_set) {
        item.rule_set = item.rule_set;
      } else {
        item.rule_set = {
          name: "",
          is_visible: 0,
          is_active: 0,
          rule_type: "PRICE_BREAK_RULE",
          rules: []
        };
      }
      this.locationPayload[item.locationId!] = item;
    });

    this.assignSelectedPriceGroupRules();
    this.showPriceGroupDropdown =
      this.product.price_settings.pricing_type === "PRICE_GROUP" ? true : false;
    this.idSelection(Number(this.currentLocation.id));
  }
}
