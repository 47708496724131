import TemplateEditor from "@/components/templateEditor/editors.declaration";
import { packagingDetailsDefault, Product } from "@/interfaces/product";
import { Vendor } from "@/interfaces/vendor";
import { templatesService } from "@/services/templates.service";
import { Callback } from "@/types/types";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import { Validator } from "vee-validate";
import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./PackagingDetail.template.vue";

/**
 * namespace to dispatch vuex actions and getters
 * @const string namespace
 */
const namespace: string = "ProductModule";

/**
 * product basic info form component
 */
@Component({
  mixins: [Template]
})
export default class PackagingDetailComponent extends Vue {
  @Inject("$validator") public $validator!: Validator;
  /**
   * action to find the vendors to the autocomplete list
   */
  @Action("findVendors", { namespace })
  public findVendorsAction!: Callback;

  public customerTemplates: TemplateEditor.TemplateModel[] = [];
  public inventoryTemplates: TemplateEditor.TemplateModel[] = [];
  @Prop({ default: false })
  public isModifiableProducts!: boolean;
  /**
   * model to save
   * @var Product product
   */
  @Prop({ required: true })
  protected product!: Product;
  @Prop({ required: true })
  protected active!: boolean;

  protected renderReady: boolean = false;

  protected isLoadingCultivator: boolean = false;
  protected isLoadingManufacturer: boolean = false;

  protected searchCultivatorField: string = "";
  protected searchManufacturerField: string = "";
  protected debounceTime = 500;

  protected debounceCultivator = debounce(
    async (scope: any, vendorName: string) => {
      if (!vendorName || vendorName.length > 2) {
        scope.isLoadingCultivator = true;
        await scope.findVendorsAction({
          vendorName,
          mutator: "setCultivators"
        });
        scope.isLoadingCultivator = false;
      }
    },
    this.debounceTime
  );

  protected debounceManufacturer = debounce(
    async (scope: any, vendorName: string) => {
      if (!vendorName || vendorName.length > 2) {
        scope.isLoadingManufacturer = true;
        await scope.findVendorsAction({
          vendorName,
          mutator: "setManufacturers"
        });
        scope.isLoadingManufacturer = false;
      }
    },
    this.debounceTime
  );

  /**
   * list of strain to display in the autocomplete field
   * @var Vendors[] vendorsItems
   */
  @Getter("manufacturers", { namespace })
  protected manufacturerItems!: Vendor[];

  /**
   * list of strain to display in the autocomplete field
   * @var Vendors[] vendorsItems
   */
  @Getter("cultivators", { namespace })
  protected cultivatorItems!: Vendor[];

  /**
   * watcher to dispatch the findVendorsAction action, when the autocomplete field is change
   * @param cultivatorName
   */
  @Watch("searchCultivatorField")
  public searchCultivator(cultivatorName: string) {
    this.debounceCultivator(this, cultivatorName);
  }

  /**
   * watcher to dispatch the findVendorsAction action, when the autocomplete field is change
   * @param manufacturerName
   */
  @Watch("searchManufacturerField")
  public searchManufacturer(manufacturerName: string) {
    this.debounceManufacturer(this, manufacturerName);
  }

  public validateNumber(event: Event) {
    // @ts-ignore
    if (event.key !== ".") {
      // @ts-ignore
      if (isNaN(event.key)) {
        event.preventDefault();
      }
    }
    return true;
  }

  public resetActivationTime() {
    if (this.product.activation_time_unit === "I") {
      this.product.activation_time = "";
    }
  }

  protected async mounted() {
    const unwatch = this.$watch("active", async () => {
      if (this.active) {
        if (!this.product.packaging_detail) {
          this.product.packaging_detail = cloneDeep(packagingDetailsDefault);
        }
        this.renderReady = true;
        [this.customerTemplates, this.inventoryTemplates] = await Promise.all([
          templatesService.getTemplateByType("CUSTOMER"),
          templatesService.getTemplateByType("INVENTORY")
        ]);
        unwatch();
      }
    });
  }
}
