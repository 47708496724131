import {
  WmBrand,
  WmProduct,
  WmVariant,
  WmVerifiedModel
} from "@/interfaces/WeedmapsData";
import { EventBus } from "@/internal";
import { weedmapsInfoService } from "@/services/weedmapsInfo.service";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import { Validator } from "vee-validate";
import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import Template from "./verifiedForm.template.vue";

@Component({
  mixins: [Template]
})
export default class VerifiedFormComponent extends Vue {
  @Inject("validator") public $validator!: Validator;
  @Prop({ required: true })
  public value!: WmVerifiedModel;
  @Prop({ required: true })
  public selectedBrandId!: number;

  public productList: WmProduct[] = [];
  public defaultList: WmProduct[] = [];
  public variantsList: WmVariant[] = [];
  public defaultVariants: WmVariant[] = [];
  public integratorBrand: WmBrand | null = null;
  public loadingProducts = false;
  public loadingVariant = false;
  public currentWMProductId = "";
  public searchVal: string | null = null;
  public dSearch = debounce(async context => {
    if (!context.searchVal) {
      context.productList = context.defaultList;
      return;
    }
    context.getProducts({
      "filter[name]": context.searchVal
    });
  }, 500);
  public get model() {
    return cloneDeep(this.value);
  }

  public emitModel() {
    this.$emit("input", this.model);
  }

  public async getProducts(
    query: {
      "filter[name]"?: string;
    } | null = null
  ) {
    this.loadingProducts = true;
    this.productList = await weedmapsInfoService.getProductsByBrand(
      query,
      this.selectedBrandId
    );
    this.loadingProducts = false;
  }

  public async onChangeProduct() {
    this.loadingVariant = true;
    this.variantsList = (await weedmapsInfoService.getProductVariants(
      this.model.weedmaps_product!.weedmaps_product_id
    )).filter(v => v.value);
    this.loadingVariant = false;
  }

  /**
   * Watchs selectedBrandId prop
   * If the model is already built (update). Check if the current prodcut belongs to the brand
   * If so, checks if its already on the list, or adds it.
   */
  @Watch("selectedBrandId", { immediate: true })
  public async brandChanged() {
    if (this.selectedBrandId) {
      this.variantsList = [];
      this.integratorBrand = await weedmapsInfoService
        .getBrand(this.selectedBrandId.toString())
        .then(async data => {
          EventBus.$emit("errorBrandWeedMaps", !data);
          if (data) {
            await this.getProducts(null);
            if (
              this.model.weedmaps_product &&
              this.model.weedmaps_product.weedmaps_brand_id ===
                data.weedmaps_brand_id
            ) {
              const inList = this.productList.find(
                prod =>
                  prod.weedmaps_product_id ===
                  this.model.weedmaps_product!.weedmaps_product_id
              );

              if (inList) {
                this.model.weedmaps_product = inList;
              } else {
                this.productList.unshift(this.model.weedmaps_product);
              }
            }
          }
          this.defaultList = this.productList;
          return data;
        });
    }
  }
  public updateProduct() {
    this.model.weedmaps_product_variant = null;
    this.emitModel();
  }

  public get isRequired() {
    return this.model.weedmaps_product &&
      this.model.weedmaps_product.weedmaps_product_id
      ? "required"
      : "";
  }

  @Watch("searchVal")
  public searchChange() {
    if (this.selectedBrandId && !this.model.weedmaps_product) {
      this.dSearch(this);
    } else if (
      this.currentWMProductId !==
      this.model.weedmaps_product!.weedmaps_product_id
    ) {
      this.currentWMProductId = this.model.weedmaps_product!.weedmaps_product_id;
      this.onChangeProduct();
    }
  }
}
