import { WmNonVerifiedModel, WmTag } from "@/interfaces/WeedmapsData";
import { weedmapsInfoService } from "@/services/weedmapsInfo.service";
import cloneDeep from "lodash/cloneDeep";
import sortBy from "lodash/sortBy";
import { Component, Prop, Vue } from "vue-property-decorator";
import { CategorySelectorComponent } from "./categorySelector/categorySelector.component";
import Template from "./nonVerifiedForm.template.vue";
import { TagSelectorComponent } from "./tagSelector/tagSelector.component";
@Component({
  mixins: [Template],
  components: {
    CategorySelectorComponent
  }
})
export default class NonVerifiedFromComponent extends Vue {
  @Prop({ required: true })
  public value!: WmNonVerifiedModel;

  public categories: WmTag[] = [];
  public selectedCategories: WmTag[] = [];
  public selectedTags: WmTag[] = [];
  public categoryTree: WmTag[] = [];
  public attributes = [
    { text: "Concentrate", value: "concentrate" },
    { text: "Pre-roll", value: "pre-roll" },
    { text: "Weight-breakpoint", value: "weight-breakpoint" },
    { text: "Size", value: "size" }
  ];

  public units = {
    concentrate: [
      { text: "Mg", value: "mg" },
      { text: "g", value: "g" },
      { text: "Oz", value: "oz" }
    ],
    "weight-breakpoint": [
      { text: "Mg", value: "mg" },
      { text: "g", value: "g" },
      { text: "Oz", value: "oz" }
    ],
    "pre-roll": [
      { text: "Unit", value: "unit" },
      { text: "Pack", value: "pack" }
    ],
    size: [
      { text: "XXS", value: "xxs" },
      { text: "XS", vlaue: "xs" },
      { text: "S", value: "s" },
      { text: "M", value: "m" },
      { text: "L", value: "l" },
      { text: "XL", value: "xl" },
      { text: "XXL", value: "xxl" }
    ]
  };

  public get model() {
    return cloneDeep(this.value);
  }

  public get currentUnits() {
    return (this.model.attribute && this.units[this.model.attribute]) || [];
  }

  public get getSelectedTags() {
    return this.model.tags.filter(t => !t.is_category);
  }
  public get getSelectedCategories() {
    return sortBy(this.model.tags.filter(t => t.is_category), ["id"]);
  }

  public emitModel() {
    this.model.tags = [...this.selectedTags, ...this.selectedCategories];
    this.$emit("input", this.model);
  }
  public updateCategories(categories: WmTag[]) {
    this.selectedCategories = categories;
    this.emitModel();
  }
  public removeCategory(category: WmTag) {
    this.selectedCategories
      .filter(sc => sc.parent_id === category.weedmaps_tag_id)
      .forEach(child => {
        this.removeCategory(child);
      });
    this.selectedCategories = this.selectedCategories.filter(
      c => c.weedmaps_tag_id !== category.weedmaps_tag_id
    );
    this.emitModel();
  }

  public removeTag(index: number) {
    this.getSelectedTags.splice(index, 1);
    this.selectedTags = [...this.getSelectedTags];
    this.emitModel();
  }

  public openTagSelector() {
    this.$modals
      .load<WmTag[]>(
        TagSelectorComponent,
        {
          title: "ADD TAG",
          size: "full_width"
        },
        {
          selectedTags: this.getSelectedTags
        }
      )
      .then(
        data => {
          this.selectedTags = data;
          this.emitModel();
        },
        () => {
          // Nothing on close
        }
      );
  }

  protected async created() {
    this.categories = await weedmapsInfoService.getTags(
      { "filter[is_category]": 1, "filter[group_id]": "1" },
      false
    );
    this.categoryTree = await weedmapsInfoService.getCategoriesTree();
    this.selectedTags = this.getSelectedTags;
    this.selectedCategories = this.getSelectedCategories;
  }
}
