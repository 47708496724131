import { ValuesForDropdown } from "@/interfaces/prescriptionDetails";
import { Product } from "@/interfaces/product";
import { Validator } from "vee-validate";
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import Template from "./PrescriptionDetails.template.vue";

/**
 * product prescription details
 */
@Component({
  mixins: [Template]
})
export default class PrescriptionDetailsComponent extends Vue {
  @Inject("validator") public $validator!: Validator;

  public valuesForQuantityUnitMeasure!: ValuesForDropdown[];
  public valuesForTimeframeUnitMeasure!: ValuesForDropdown[];
  public valuesForDosageUnitOfMeasure!: ValuesForDropdown[];
  @Prop({ default: false })
  public disabled!: boolean;
  @Prop({ required: true })
  protected product!: Product;

  public validCriteria(
    currentField: string | null,
    dependency1: string | null,
    dependency2: string | null
  ) {
    return !!currentField || (!!dependency1 && !dependency2);
  }

  public validateQuantity(e: KeyboardEvent, value: string | number | null) {
    if (!/^[0-9]?\.?$/.test(e.key)) {
      e.preventDefault();
    }
    if (
      value &&
      !/^[0-9]{0,7}(\.[0-9]?[0-9]?[0-9]?)?$/.test(value.toString())
    ) {
      e.preventDefault();
    }
  }

  public validateSupply(e: KeyboardEvent, value: string | number | null) {
    if (!/^[0-9]?\.?$/.test(e.key)) {
      e.preventDefault();
    }
    if (value && !/^[0-9]{0,7}(\.[0-9]?[0-9]?)?$/.test(value.toString())) {
      e.preventDefault();
    }
  }
  protected created() {
    this.valuesForQuantityUnitMeasure = [
      { value: "Grams", text: this.$t("grams") },
      { value: "Ounces", text: this.$t("ounces") },
      { value: "Capsules", text: this.$t("capsules") },
      { value: "Each", text: this.$t("each") },
      { value: "Units", text: this.$t("units") },
      { value: "Count", text: this.$t("count") },
      { value: "Milliliters", text: this.$t("milliliters") },
      { value: "Centiliters", text: this.$t("centiliters") },
      { value: "Fluid Ounce", text: this.$t("limit.fluid_ounces") }
    ];
    this.valuesForDosageUnitOfMeasure = [
      { value: "Grams", text: this.$t("grams") },
      { value: "Ounces", text: this.$t("ounces") },
      { value: "Units", text: this.$t("units") },
      { value: "Count", text: this.$t("count") },
      { value: "Capsules", text: this.$t("capsules") },
      { value: "Milliliters", text: this.$t("milliliters") },
      { value: "Centiliters", text: this.$t("centiliters") },
      { value: "Fluid Ounce", text: this.$t("limit.fluid_ounces") },
      { value: "Each", text: this.$t("each") },
      { value: "Puffs", text: this.$t("limit.puffs") }
    ];

    this.valuesForTimeframeUnitMeasure = [
      { value: "Minutes", text: this.$t("minutes") },
      { value: "Hours", text: this.$t("hours") },
      { value: "Days", text: this.$t("days") },
      { value: "Weeks", text: this.$t("weeks") },
      { value: "Months", text: this.$t("months") }
    ];
  }
}
