import { WmTag } from "@/interfaces/WeedmapsData";
import { weedmapsInfoService } from "@/services/weedmapsInfo.service";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import findIndex from "lodash/findIndex";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Template from "./tagSelector.template.vue";

interface DisplayTags {
  [groupName: string]: {
    items: WmTag[];
    is_mutually_exclusive: 0 | 1;
  };
}

@Component({
  mixins: [Template]
})
export class TagSelectorComponent extends Vue {
  @Prop({ default: [] }) public selectedTags!: WmTag[];

  public tagSet: WmTag[] = [];
  public displayTags: DisplayTags = {};
  public searchValue: string | null = null;
  public currentSelection: WmTag[] = [];
  public loading = false;
  public dSearch = debounce(async context => {
    context.loading = true;
    context.tagSet = await weedmapsInfoService.getTags({
      "filter[name]": context.searchValue || "",
      "filter[is_category]": 0,
      per_page: 100
    });
    context.parseTags();
    context.loading = false;
  }, 500);

  public confirm() {
    this.$emit("resolve", this.currentSelection);
  }

  public onSelect(selected: WmTag[]) {
    const filtered = selected.reduce((acc: WmTag[], tag) => {
      if (tag.is_mutually_exclusive) {
        const excIndex = findIndex(acc, rt => rt.group_name === tag.group_name);
        if (excIndex !== -1) {
          acc.splice(excIndex, 1);
        }
      }
      acc.push(tag);
      return acc;
    }, []);
    this.currentSelection = filtered;
  }

  @Watch("searchValue")
  public searchTags() {
    this.dSearch(this);
  }

  protected parseTags() {
    this.displayTags = this.tagSet.reduce((acc: DisplayTags, tag) => {
      const groupName = tag.group_name!;
      if (!acc[groupName]) {
        acc[groupName] = {
          items: [],
          is_mutually_exclusive: tag.is_mutually_exclusive!
        };
      }
      acc[groupName].items.push(tag);

      return acc;
    }, {});
  }
  protected async mounted() {
    this.loading = true;
    this.tagSet = await weedmapsInfoService.getTags({
      "filter[is_category]": 0,
      per_page: 100
    });
    if (this.selectedTags.length) {
      this.$nextTick(() => {
        this.currentSelection = cloneDeep(this.selectedTags);
      });
    }

    this.parseTags();
    this.loading = false;
  }
}
