import { Location } from "@/interfaces/location";
import { MemberLevel, MemberLevelType } from "@/interfaces/memberLevel";
import { PriceGroupInterface } from "@/interfaces/priceGroups";
import { PriceTab, PricingRule } from "@/interfaces/product";
import { TaxCategory } from "@/interfaces/taxCategory";
import { memberLevelService } from "@/services/memberLevel.service";
import { messagesService } from "@/services/messages.service";
import { taxCategoryService } from "@/services/taxCategory.service";
import cloneDeep from "lodash/cloneDeep";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import SharedPricePointMainComponent from "../sharedMainPoint/SharedMainPoint.component";
import Template from "./SharedPricePoint.template.vue";

@Component({
  mixins: [Template],
  components: {
    SharedPricePointMainComponent
  }
})
export default class SharedPricePointStructureComponent extends Vue {
  public get prices() {
    const priceType = this.tabs[this.activeTab].type;
    return this.value.filter(price => price.pricing_type === priceType);
  }
  public tabs: PriceTab[] = [
    {
      type: "WHOLESALE_INBOUND",
      title: "product_details.wholesale_inbound",
      isWholesale: true
    },
    {
      type: "WHOLESALE_OUTBOUND",
      title: "product_details.wholesale_outbound",
      isWholesale: true
    }
  ];
  public loading = true;
  public activeTab = 0;
  public locationIsRecreational = false;
  public locationIsMedical = false;
  @Prop() public taxCategoryId!: TaxCategory;
  @Prop({ default: false })
  public isDisable!: boolean;
  public memberItemsUpdated: MemberLevel[] = [];
  @Prop() public ruleModel!: PricingRule[];
  @Prop({ default: false }) public batchSelected!: boolean;
  @Prop({ default: false }) public productSelected!: boolean;
  @Prop() public priceGroupSelectedId!: number | null;
  @Prop() public priceGroupAssigned!: PriceGroupInterface;
  @Prop({ required: true })
  protected currentLocation!: Location;
  @Prop({ default: null })
  protected unitUsableWeight!: string;
  @Prop({ default: () => [] })
  protected value!: PricingRule[];
  @Prop({ default: false })
  protected priceByUsable!: boolean;
  @Prop({ default: false })
  protected requiresWeighing!: boolean;
  @Prop({ default: true })
  protected isModifyAblePriceGroup!: boolean;
  @Prop({ default: true })
  protected isModifiableProducts!: boolean;
  @Prop({ required: true })
  protected memberItems!: MemberLevel[];
  @Prop({ required: true })
  protected priceTaxCategories!: TaxCategory[];

  @Prop({ default: "" })
  protected pricingStyleSelected!: string;
  @Prop({ default: "" })
  protected pricingTypeSelected!: string;
  @Prop({ default: false })
  protected isOnlyPriceGroup!: boolean;
  /**
   * taxCategories
   * @var TaxCategory[] taxCategories
   */
  protected taxCategories: TaxCategory[] = [];

  /**
   * memberLevels
   * @var MemberLevel[] memberLevels
   */
  protected memberLevels: MemberLevel[] = [];

  public setPricingStyle(event: string) {
    this.prices[0].pricing_style = event;
  }
  public deleteRules() {
    this.value.forEach(item => {
      if (this.activeTab === 0 && item.pricing_type === "RETAIL_MEDICAL") {
        item.price_breaks = [];
      } else if (
        this.activeTab === 0 &&
        item.pricing_type === "RETAIL_RECREATIONAL"
      ) {
        item.price_breaks = [];
      } else if (
        this.activeTab === 1 &&
        item.pricing_type === "WHOLESALE_INBOUND"
      ) {
        item.price_breaks = [];
      } else if (
        this.activeTab === 2 &&
        item.pricing_type === "WHOLESALE_OUTBOUND"
      ) {
        item.price_breaks = [];
      }
    });
  }
  public copyPrices(copiedPrices: PricingRule[]) {
    const oldRules = cloneDeep(this.value);
    const newPrices = copiedPrices.reduce((acc: PricingRule[], rule) => {
      const indexOldRule = oldRules.findIndex(
        r =>
          r.member_level_code === rule.member_level_code &&
          r.pricing_type === rule.pricing_type
      );
      const oldRule = indexOldRule > -1 ? oldRules.splice(indexOldRule, 1) : [];
      const newRule = oldRule.length ? { ...rule, id: oldRule[0].id } : rule;
      acc.push(newRule);
      return acc;
    }, []);
    messagesService.renderSuccessMessage("price_point_copy_success");
    this.$emit("input", [
      ...newPrices,
      ...oldRules.map(d => ({ ...d, price_breaks: [] })) // Se coloca price_breaks vacio para desactivar la regla
    ]);
  }

  public setPrices(event: { rules: PricingRule[]; priceType: string }) {
    const otherRules: PricingRule[] = this.value!.filter(
      price => price.pricing_type !== event.priceType
    );
    this.$emit("input", [...otherRules, ...event.rules]);
  }

  public buildTabs() {
    if (this.locationIsRecreational) {
      this.tabs.unshift({
        type: "RETAIL_RECREATIONAL",
        title: "product_details.recreational_price_point",
        isWholesale: false
      });
    }

    if (this.locationIsMedical) {
      this.tabs.unshift({
        type: "RETAIL_MEDICAL",
        title: "product_details.medical_price_point",
        isWholesale: false
      });
    }
  }

  protected setLocationTypes() {
    this.locationIsRecreational =
      this.currentLocation.location_type === "RETAIL_RECREATIONAL" ||
      this.currentLocation.location_type === "RETAIL_MEDICAL_RECREATIONAL";

    this.locationIsMedical =
      this.currentLocation.location_type === "RETAIL_MEDICAL" ||
      this.currentLocation.location_type === "RETAIL_MEDICAL_RECREATIONAL";
  }

  protected async mounted() {
    this.setLocationTypes();
    this.buildTabs();
    this.loading = false;
  }
}
